import html2pdf from 'html2pdf.js';

const CreatePDF = (content, fileName) => {
    html2pdf().set({
        margin: 0,
        image: { type: 'jpeg', quality: 1 }, // Use JPEG and reduce the quality slightly
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: 'avoid-all' }, // Make sure to avoid awkward page breaks
        html2canvas: { 
            scale: 1.5, // A good balance between quality and file size
            useCORS: true,
            logging: false, // Disable logging for performance
            letterRendering: true // Improves text rendering
        } 
    }).from(content).save(fileName);
}

const GetFriendlyCPF = (value) => {
    let cpf = value.toString().replace(/[.,]/g, "");
    if(cpf.length === 0)
    {
        return "";
    }
    if (cpf.length < 11) {
        cpf = "0".repeat(11 - cpf.length) + cpf;
    }
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export {
    CreatePDF,
    GetFriendlyCPF
};
