import React, { useEffect, useState } from "react";

import './Header.css';
import OptionSelector from '../../../components/option-selector/OptionSelector';
import CalendarConfig from "../../../consts/CalendarConfig";
import CustomButton from "../../../components/buttons/CustomButton";
import ArrowLeft from '@mui/icons-material/ArrowBackIosRounded';
import ArrowRight from '@mui/icons-material/ArrowForwardIosRounded';
import { IconButton } from '@mui/material';

function Header(props) {
    const { OnSelectViewMode, OnPrev, OnNext, OnToday, Month, Year, isMobile } = props;

    function OnSelectViewModeHandler(value) {
        OnSelectViewMode(value);
    }

    function OnTodayClickHandler() {
        OnToday();
    }

    function OnPrevClickHandler() {
        OnPrev();
    }

    function OnNextClickHandler() {
        OnNext();
    }

    return (
        <div className="appointments-header-container">
            <div className="appointments-header-date-container">
                <h4>{Month} {Year}</h4>
            </div>
            <div className="appointments-header-buttons-container">
                <CustomButton onClick={OnPrevClickHandler} variant="outlined" style="secondary" icon={<ArrowLeft fontSize="10px" />} size="small" />
                <CustomButton onClick={OnTodayClickHandler} variant="outlined" style="secondary" label={"Hoje"} />
                <CustomButton onClick={OnNextClickHandler} variant="outlined" style="secondary" size="small" icon={<ArrowRight fontSize="10px" />} />
            </div>
            <OptionSelector Options={CalendarConfig.ViewModes} InitialSelection={1} OnSelect={OnSelectViewModeHandler} />
        </div>
    )
}

export default Header;