import React, { useEffect, useState, useRef } from 'react';
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import IconMoney from '@mui/icons-material/MonetizationOnOutlined';
import IconPrint from '@mui/icons-material/Print';
import { CreatePDF } from '../../utils/Utils.js';
import styles from "./styles.module.css";

import { DataProviderInstance } from "../../api/DataProvider";

import DocumentDefaultTemplate from "../../components/documents-templates/DocumentDefaultTemplate";
import { LocalizationProvider, TimeField, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { GetFriendlyCPF } from '../../utils/Utils';
import Alert from '@mui/material/Alert';

function CreateDocumentPopup(props) {

    const { Data, OnHide } = props;
    const { CompanyConfig, GetPatientById, SetPatientDocument } = DataProviderInstance();

    let templates = [...CompanyConfig.DocumentTemplates, { Id: -1, Title: "Em branco", Text: "" }];

    const appointmentData = Data;
    const patientData = GetPatientById(appointmentData.patient);
    const professionalData = CompanyConfig.Resources.find((item) => item.id == appointmentData.resource);
    const procedureData = CompanyConfig.Procedures.find((item) => item.Id == appointmentData.procedure);

    const initialIndex = 0;
    const [selectedOption, setSelectedOption] = useState(initialIndex);
    const [currentTitle, setCurrentTitle] = useState(null);
    const [currentText, setCurrentText] = useState(null);

    const pdfContentRef = useRef(null);

    useEffect(() => {
        setSelectedOption(initialIndex);
        setCurrentTitle(GetCurrentTitle(initialIndex));
        setCurrentText(GetCurrentText(initialIndex));
    }, []);

    function OnCloseClickHandler() {
        Hide();
    }

    function Hide() {
        OnHide();
    }

    const [isPrinting, setIsPrinting] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const createClickHandler = () => {

        setIsSaved(true);
        setIsPrinting(true);
        SetPatientDocument(appointmentData.patient, dateIssue.format('YYYY-MM-DD'), appointmentData.id, currentTitle, currentText, (response) => {
            OnPrintHandler();
        });
    }

    function GetFooter() {
        return (
            <div className={styles.footer}>
                <CustomButton variant="outlined" style="secondary" label={"Cancelar"} onClick={OnCloseClickHandler} />
                {isSaved ? <CustomButton variant="contained" style="primary" label={"Imprimir"} icon={<IconPrint />} onClick={OnPrintHandler} enabled={!isPrinting} /> :
                    <CustomButton variant="contained" style="primary" label={"Salvar e imprimir"} icon={<IconPrint />} onClick={createClickHandler} enabled={!isPrinting} />}
            </div>
        )
    }

    function OnPrintHandler() {

        let documentTypeName = templates[selectedOption].Title;
        let currentDate = new Date();
        let formattedDate = currentDate.getFullYear() + "_" + (currentDate.getMonth() + 1) + "_" + currentDate.getDate() + "_" + currentDate.getHours() + "_" + currentDate.getMinutes();
        let patientName = patientData.name;

        let fileName = `${patientName}_${formattedDate}_${documentTypeName}.pdf`.replace(/\s/g, '_');
        let content = pdfContentRef.current;

        CreatePDF(content, fileName);
        setIsPrinting(false);
    }

    function GetCurrentTitle(index) {
        const template = templates.find((item) => item.Id == index);

        return template.Title;
    }

    function parseDuration(duration) {
        const [hours, minutes, seconds] = duration.split(':');
        return (parseInt(hours) * 60) + parseInt(minutes);
    }

    function GetCurrentText(index) {
        const template = templates.find((item) => item.Id == index);

        const appointmentDate = new Date(appointmentData.date);

        const friendyDate = appointmentDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' });
        const appointmentStartTime = appointmentDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
        const appointmentEndTime = new Date(appointmentDate.getTime() + (parseDuration(procedureData.Duration) * 60000)).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

        const cpf = GetFriendlyCPF(patientData.cpf);
        const price = appointmentData.price;

        const text = template.Text
            .replace("{patient-name}", "" + patientData.name + "")
            .replace("{patient-cpf}", "" + cpf + "")
            .replace("{appointment-date}", "" + friendyDate + "")
            .replace("{appointment-time}", "" + appointmentStartTime + "")
            .replace("{appointment-reason}", "")
            .replace("{appointment-price}", "" + price + "")
            .replace("{appointment-startTime}", "" + appointmentStartTime + "")
            .replace("{appointment-endTime}", "" + appointmentEndTime + "")
            .replaceAll('\n', '<br>');  

        return text;
    }

    function getCurrentDate() {
        return dateIssue.format('DD/MM/YYYY');
    }

    function handleOptionChange(event) {
        setSelectedOption(event.target.value);
        setCurrentTitle(GetCurrentTitle(event.target.value));
        setCurrentText(GetCurrentText(event.target.value));
    }

    const [dateIssue, setDateIssue] = useState(dayjs(appointmentData.date, 'YYYY-MM-DD'));

    function GetContent() {

        if (currentText == null && currentTitle == null) {
            return <p>Selecione um paciente e um profissional para gerar o documento.</p>
        }
        else {
            return (
                <div className={styles.mainContainer}>
                    <div className={styles.line}>
                        <div className={styles.item}>
                            <h5>Data emissão:</h5>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    label="Data"
                                    defaultValue={dateIssue}
                                    format='DD/MM/YYYY'
                                    onChange={(newValue) => setDateIssue(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className={styles.item}>
                            <h5>Modelo de documento:</h5>
                            <FormControl>
                                <Select
                                    labelId="select-label"
                                    id="select"
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                >
                                    {templates.map((option, index) => (
                                        <MenuItem key={index} value={option.Id}>{option.Tag}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {(appointmentData.invoiceId === "0") ? <Alert severity="error">Consulta não faturada.</Alert> : null}
                    <h5>Título:</h5>
                    <TextField
                        value={currentTitle}
                        fullWidth
                        onChange={(event) => setCurrentTitle(event.target.value)}
                    />
                    <h5>Descrição:</h5>
                    <TextField
                        value={currentText}
                        multiline
                        rows={5}
                        fullWidth
                        onChange={(event) => setCurrentText(event.target.value)}
                    />
                    <h5>Arquivo final:</h5>
                    <div ref={pdfContentRef} >
                        <DocumentDefaultTemplate date={getCurrentDate()} companyData={CompanyConfig} patientData={patientData} professionalData={professionalData} title={currentTitle} description={currentText} />
                    </div>
                </div>
            )
        }
    }

    return <PopupBase Title={"Emitir documento"} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
}

export default CreateDocumentPopup;