import React from 'react';
import MainPain from '../../../appointment/components/mainPain/MainPain';
import AnamneseHistory from '../../../appointment/components/anamneseHistory/AnamneseHistory';
import PatientHistory from '../../../appointment/components/patientHistory/PatientHistory';

const HealthHistory = ({ complaints, anamneses, evolutions }) => {

    let initialComplaints = [];

    if (complaints && complaints.length > 0) {
        initialComplaints = JSON.parse(complaints[0].list);
    }

    function onChangeComplaintsHandler() { }
    function onAssesmentChangeHandler() { }
    function onEvolutionChangeHandler() { }
    function ReloadPage() { }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap:"20px" }}>
            <MainPain list={initialComplaints} onChange={onChangeComplaintsHandler} enabled={false} />
            <div style={{ display: "flex", flexDirection: "row", gap:"40px", flexWrap:"wrap" }}>
                <AnamneseHistory patientId={null} currentDate={''} list={anamneses} onUpdated={ReloadPage} enabled={false} />
                <PatientHistory data={evolutions} enabled={false} onAssesmentChange={onAssesmentChangeHandler} onEvolutionChange={onEvolutionChangeHandler} />
            </div>
        </div>
    );
};

export default HealthHistory;