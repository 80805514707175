import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Document.module.css';
import Loading from '../../components/loading/Loading.js';
import CustomButton from '../../components/buttons/CustomButton.js';
import IconPrint from '@mui/icons-material/Print';
import { CreatePDF } from '../../utils/Utils.js';
import { DataProviderInstance } from "../../api/DataProvider.js";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function Document() {

  const { id } = useParams();
  const [appointmentData, setAppointmentData] = useState(null);
  const [patientData, setPatientData] = useState(null);

  const pdfContentRef = useRef(null);

  const { CompanyConfig, GetPatientById, GetAppointmentById } = DataProviderInstance();

  useEffect(() => {
    LoadData();
  }, [id]);

  function LoadData() {
    let appointment = GetAppointmentById(id);
    let patient = GetPatientById(appointment.patient);
    setAppointmentData(appointment);
    setPatientData(patient);
  };

  function OnPrintHandler() {
    const fileName = `${appointmentData.patient.name.replace(/\s/g, '_')}_${appointmentData.invoice.createdAt}_n${id}.pdf`;
    const content = pdfContentRef.current;

    CreatePDF(content, fileName);
  }


  const templates = [
    {id:0,label:"Encaminhamento médico", text:"Encaminhamos o paciente {userName} mencionado para a realização de exames complementares."},
    {id:1,label:"Atestado de presença", text:"Certificamos que o paciente {userName} mencionado compareceu ao atendimento no dia {date}."},
    {id:2,label:"Atestado de acompanhamento", text:"Certificamos que o paciente {userName} mencionado está em acompanhamento médico."},
  ];

  const [selectedOption, setSelectedOption] = useState(0);

  function GetCurrentText() {
    const template = templates.find((item) => item.id === selectedOption);
    const text = template.text.replace("{userName}", patientData.name).replace("{date}", appointmentData.date);

    return (
      <div>
        <p>{text}</p>
      </div>
    );
  }


  function handleOptionChange(event) {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  }

  if (appointmentData == null) {
    return <Loading />;
  } else {

    return (
      <div className='page-content' >

        <div ref={pdfContentRef}>
          <p>Nome:</p>
        </div>

        <FormControl>
          <InputLabel id="select-label">Selecione uma opção</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            {templates.map((option, index) => (
              <MenuItem key={index} value={option.id}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {GetCurrentText()}

        <CustomButton
          variant="outlined"
          style="primary"
          label={"Imprimir"}
          icon={<IconPrint />}
          onClick={OnPrintHandler}
        />
      </div>
    );
  }
}

export default Document;