import React from "react";
import styles from "./AreaTitle.module.css";
import CircleIcon from "../circleIcon/CircleIcon";

function AreaTitle({ Title, Icon, Description }) {
    return (
        <div className={styles.container}>
            {Icon && <CircleIcon icon={Icon} style={"primary"} />}
            <div className={styles.containerTexts}>
                <h2> {Title}</h2>
                <p>{Description}</p>
            </div>
        </div>
    );
}

export default AreaTitle;