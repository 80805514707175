import React from 'react';
import styles from './PatientInfo.module.css';
import ViewMoreIcon from "@mui/icons-material/ContactPageOutlined";
import PatientConfig from '../../../../consts/PatientConfig';
import CustomButton from '../../../../components/buttons/CustomButton';
import Picture from '../../../../assets/images/patient-picture.png';
import CalendarConfig from '../../../../consts/CalendarConfig';

const PatientInfo = ({ data }) => {

  const genderLabel = PatientConfig.GetGenderById(data.gender).Label;

  const birthday = new Date(data.birthday);
  const age = new Date().getFullYear() - birthday.getFullYear();

  function OnPatientInfoClickHandler() {
    window.open(`/patients/${data.id}`);
  }

  return (
    <div className={styles.container}>
      <h3>Informações do paciente</h3>
      <div className={styles.containerAllInfo}>
        <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "20px", alignItems: "center" }}>
          <div className={styles.imageContainer}>
            <img src={Picture} width={"100%"} height={"100%"} />
          </div>
          <div className={styles.containerInfo}>
            <h4 className={styles.name}>{data.name}</h4>
            <p className={styles.birthday}>{data.birthday ? CalendarConfig.GetFullDateName(data.birthday) + " (" + age + " anos)" : "Idade desconhecida"}</p>
            <p className={styles.gender}>{genderLabel}</p>
          </div>
        </div>
        <CustomButton label={"Ficha"} style="primary" variant="outlined" onClick={OnPatientInfoClickHandler} icon={<ViewMoreIcon />} />
      </div>
    </div>
  );
};

export default PatientInfo;
