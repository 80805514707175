import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import 'dayjs/locale/pt-br';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Box } from '@mui/material';
import ColorPalette from '../../../../../colorpalette/ColorPalette';

dayjs.extend(isBetweenPlugin);
dayjs.locale('pt-br');

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered' && prop !== 'isWeekView',
})(({ theme, isSelected, isHovered, day, isWeekView }) => ({
  borderRadius: isWeekView ? 0 : '50%', // Default rounded style for day view
  margin: 0, // No gap between days
  ...(isSelected && {
    backgroundColor: ColorPalette.secondary,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: ColorPalette.secondary,
    },
  }),
  ...(isHovered && isWeekView && {
    backgroundColor: ColorPalette.secondary,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: ColorPalette.secondary,
    },
  }),
  ...(day.day() === 0 && isWeekView && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 6 && isWeekView && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }
  return dayA.isSame(dayB, 'week');
};

function Day(props) {
  const { day, selectedDay, hoveredDay, isWeekView, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      selected={false}
      isSelected={isWeekView ? isInSameWeek(day, selectedDay) : day.isSame(selectedDay, 'day')}
      isHovered={isWeekView && isInSameWeek(day, hoveredDay)}
      isWeekView={isWeekView}
    />
  );
}

const CalendarDatePicker = ({ currentDate, viewDaysCount, onChange }) => {
  const [hoveredDay, setHoveredDay] = useState(null);
  const [value, setValue] = useState(dayjs(currentDate));
  const isWeekView = viewDaysCount > 1;

  const handleDateChange = (newValue) => {
    setValue(newValue);
    onChange(newValue.toDate());
  };

  useEffect(() => {
    setValue(dayjs(currentDate));
  }, [currentDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-br">
      <Box sx={{ maxWidth: '100%', marginLeft:"-20px", marginTop:"-20px", padding: 0}}>
        <DateCalendar
          value={value}
          onChange={handleDateChange}
          showDaysOutsideCurrentMonth
          slots={{ day: Day }}
          slotProps={{
            day: (ownerState) => ({
              selectedDay: value,
              hoveredDay,
              onPointerEnter: () => setHoveredDay(ownerState.day),
              onPointerLeave: () => setHoveredDay(null),
              isWeekView,
            }),
          }}
          dayOfWeekFormatter={(day) => {
            const dayOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
            return dayOfWeek[day];
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CalendarDatePicker;
