import React from 'react';
import styles from './Header.module.css';
import IconAddress from '@mui/icons-material/LocationOn';
import IconPhone from '@mui/icons-material/Phone';


const Header = ({ logo, title, address, CNPJ, phone }) => {
    return (
        <div className={styles.container}>
            <img className={styles.logo} src={logo} alt="Logo" />
            <div className={styles.infos}>
                <div >
                    <h3>{title}</h3>
                    <p>CNPJ: {CNPJ}</p>
                </div>
                <div className={styles.info}>
                    <IconAddress fontSize='10px'/>
                    <p>{address}</p>
                </div>
                <div className={styles.info}>
                    <IconPhone  fontSize='10px'/>
                    <p>{phone}</p>
                </div>
                <div className={styles.info}>
                </div>
            </div>
        </div>
    );
};

export default Header;