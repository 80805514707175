import React, { forwardRef, useState, useImperativeHandle } from "react";
import './InputField.css';
import { TextField } from "@mui/material";
import InputMask from 'react-input-mask';

const InputField = forwardRef((props, ref) => {
    const { Mandatory, Label, Value, Disabled, OnUpdateValue, Mask, rows: Lines, type } = props;

    const [currentValue, setCurrentValue] = useState(Value ? Value : "");
    const [error, setError] = useState(false);

    useImperativeHandle(ref, () => ({
        Validate() {
            if (ValidateValue()) {
                SetNormalState();
                return true;
            } else {
                SetErrorState();
                return false;
            }
        },
        GetValue() {
            return currentValue;
        },
        SetValue(value) {
            setCurrentValue(value);
        },
        SetError()
        {
            SetErrorState();
        },
        Clear() {
            setCurrentValue(""); 
        }
    }));

 function ValidateValue() {
        if (Mandatory) {
            if (currentValue.trim() === "") {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    function SetNormalState() {
        setError(false);
    }

    function SetErrorState() {
        setError(true);
    }

    function Render() {
        if (Mask) {
            return (
                <div style={{display:"flex", flex:1}}>
                <InputMask mask={Mask} value={currentValue} onChange={(e) => OnValueChangeHandler(e.target.value)} onFocus={SetNormalState} disabled={Disabled}>
                    {(inputProps) => (
                        <TextField fullWidth
                            size="small"
                            type = {type}
                            label={Label}
                            {...inputProps}
                            required={Mandatory}
                            disabled={Disabled}
                            error={error}
                            multiline={Lines > 1}
                            rows={Lines > 1 ? Lines : undefined}
                        />
                    )}
                </InputMask>
                </div>
            );
        } else {
            return (
                <div style={{display:"flex", flex:1}}>
                <TextField fullWidth
                    size="small"
                    type = {type}
                    label={Label}
                    value={currentValue}
                    onChange={(e) => OnValueChangeHandler(e.target.value)}
                    onFocus={SetNormalState}
                    disabled={Disabled}
                    required={Mandatory}
                    error={error}
                    multiline={Lines > 1}
                    rows={Lines > 1 ? Lines : undefined}
                />
                </div>
            );
        }
    }

    function OnValueChangeHandler(value) {
        setCurrentValue(value);
        if (OnUpdateValue) OnUpdateValue(value);
    }

    return Render();
});

export default InputField;