import React, { useState } from "react";
import PopupBase from "../base/PopupBase";
import { DataProviderInstance } from '../../api/DataProvider';
import CustomButton from "../../components/buttons/CustomButton";
import styles from './styles.module.css';
import CircleIcon from '../../components/circleIcon/CircleIcon';
import Icon from "@mui/icons-material/CheckCircle";

function AppointmentDoneConfirmPopupContent(props) {
    const
        {
            CompanyConfig,
        } = DataProviderInstance();

    const { OnSave, OnHide, Data } = props;

    function OnCloseClickHandler() {
        Hide();
    }

    function OnCancelClickHandler() {
        Hide();
    }

    function OnConfirmClickHandler() {
        OnSave();
        Hide();
    }

    function Hide() {
        OnHide();
    }

    function GetTitle() {

        return "Confirme os dados do seu atendimento";
    }

    function CheckEvolutions() {
    
    }

    function GetContent() {
        const startTime = new Date(Data.appointment.date);
        const endTime = new Date(startTime.getTime() + Data.duration * 1000);
        const formattedStartDate = `${startTime.getDate().toString().padStart(2, '0')}/${(startTime.getMonth() + 1).toString().padStart(2, '0')}/${startTime.getFullYear()}`;
        const formattedStartTime = `${startTime.toLocaleTimeString()}`;
        const formattedEndTime = `${endTime.toLocaleTimeString()}`;

        return (

            <div className={styles.container}>
            <CircleIcon icon={<Icon />} />
                <div className={styles.containerInfos}>
                    <div>
                    <b>{Data.patient.name}</b>
                    <p>{formattedStartDate} | {formattedStartTime} > {formattedEndTime}</p>
                    </div>
                    <div>
                    <p> <b>Entrada:</b> {Data.todayAssessment}</p>	
                    <p> <b>Saída:</b> {Data.todayEvolution}</p>	
                    {!CheckCanRegister() && <p className={styles.error}>Preencha os campos de entrada e saída</p>}
                    </div>
                </div>
            </div>
        );
    }

    
    function CheckCanRegister() {
        return Data.todayAssessment != "" && Data.todayEvolution != "";
    }

    function GetFooter() {
        return (
            <div className={styles.footer}>
                <CustomButton variant="outlined" style="secondary" label={"Cancelar"} onClick={OnCancelClickHandler} />
                <CustomButton style="primary" variant="contained" label={"Registrar"} onClick={OnConfirmClickHandler} enabled={CheckCanRegister()} />
            </div>
        )
    }

    function GetRender() {

        return <PopupBase Title={GetTitle()}  Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
    }

    return (GetRender());
}

export default AppointmentDoneConfirmPopupContent;