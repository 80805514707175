import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SidebarItem.css';

function SidebarItem(props) {
  const { path, icon, label, isOpen } = props;
  let active = useLocation().pathname == path;
  const [over, setOver] = useState(false);

  const handleMouseOver = () => {
    if (!active) {
      setOver(true);
    }
  };

  const handleMouseOut = () => {
    setOver(false);
  };

  function GetCurrentState() {

    if (isOpen) {
      if (active)
        return "sidebar-label-active";
      else {
        if (over)
          return "sidebar-label-hover";
        else
          return "sidebar-label";
      }
    }
    else {

    }
  }

  return (
    <div className={active ? "sidebar-item-actived" : "sidebar-item"} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <NavLink to={path} style={{ textDecoration: 'none' }}>
        <div className={active ? "sidebar-item-icon-actived" : "sidebar-item-icon"}>
          {icon}         
        </div>
      </NavLink>
    </div>
  );
}

export default SidebarItem;