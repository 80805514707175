import React from 'react';
import style from './MessageItem.module.css';

const MessageView = (props) => {

    const { data, onSendMessage } = props;

    const handleItemClick = (message) => {
        onSendMessage(data.id, data.sender, message);
    };

    return (
        <div>
            <div>
                <div>Sender: {data.sender}</div>
                <div>At: {data.timestamp}</div>
                <div>{data.text}</div>
            </div>
            <div>
                <input type="text" />
                <button onClick={() => handleItemClick(document.querySelector('input').value)}>Click me</button>
            </div>
        </div>
    );
};

export default MessageView;
