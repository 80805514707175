import React from 'react';
import CalendarConfig from '../../../../consts/CalendarConfig';
import styles from './PatientHistoryItem.module.css';
import DateIcon from "@mui/icons-material/FiberManualRecordOutlined";
import AssessmentIcon from "@mui/icons-material/ExpandMore";
import EvolutionIcon from "@mui/icons-material/ExpandLess";

const PatientHistoryItem = ({ date, assessment, evolution }) => {

    const formattedDate = CalendarConfig.GetDDMMAAAA(date);

    return (
        <div className={styles.container}>
            <div className={styles.containerDate}>
                <span className={styles.date}>{formattedDate}</span>
            </div>
            <div className={styles.containerInfo}>
                <div className={styles.containerInfoItem}>
                    <AssessmentIcon />
                    <span>{assessment}</span>
                </div>
                <div className={styles.containerInfoItem}>
                    <EvolutionIcon />
                    <span>{evolution}</span>
                </div>
            </div>
        </div>
    );
};

export default PatientHistoryItem;
