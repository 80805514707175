import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './Invoice.module.css';
import Loading from '../../components/loading/Loading.js';
import { GetInvoiceById } from '../../api/API.js';
import CustomButton from '../../components/buttons/CustomButton.js';
import IconPrint from '@mui/icons-material/Print';
import { CreatePDF } from '../../utils/Utils.js';
import { DataProviderInstance } from "../../api/DataProvider";

function Invoice() {

  const { id } = useParams();
  const [data, setData] = useState(null);
  const pdfContentRef = useRef(null);

  const { CompanyConfig, SetAppointmentInvoice, GetPatientById } = DataProviderInstance();

  useEffect(() => {
    LoadData();
  }, [id]);

  function LoadData() {
    GetInvoiceById(id, (result) => {
      setData(result);
    });
  };

  function OnPrintHandler() {
    const fileName = `${data.patient.name.replace(/\s/g, '_')}_${data.invoice.createdAt}_n${id}.pdf`;
    const content = pdfContentRef.current;

    CreatePDF(content, fileName);
  }

  if (data == null) {
    return <Loading />;
  } else {

    let paymentMethod = CompanyConfig.PaymentOptions.find(option => option.Id == data.invoice.paymentMethod).Label;
    let date = new Date(data.invoice.createdAt).toLocaleString();

    return (
      <div className='page-content' >
        
        <div ref={pdfContentRef}>
          <p>Nome: {data.patient.name}</p>
          <p>Data: {date}</p>
          <p>Valor: {data.invoice.price}</p>
          <p>Método: {paymentMethod}</p>
        </div>

        <CustomButton
          variant="outlined"
          style="primary"
          label={"Imprimir"}
          icon={<IconPrint />}
          onClick={OnPrintHandler}
        />
      </div>
    );
  }
}

export default Invoice;