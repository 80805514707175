import React from 'react';
import './PatientItem.css';
import PatientStatus from "./status/PatientStatus";
import { NavLink } from 'react-router-dom';
import CustomButtom from '../../../components/buttons/CustomButton';
import ProfileIcon from '@mui/icons-material/ReceiptLongOutlined';
import { GetFriendlyCPF } from '../../../utils/Utils';
import { SendWhatsAppMessage } from "../../../api/WhatsappAPI";
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import MessagesTemplate from '../../../consts/MessagesTemplate';

function PatientItem(props) {
    const { Id, Status, Name, CPF, Phone, OnSelect } = props;

    function OnSelectHandler() {
        OnSelect(Id);
    }

    function onWhatsappClickHandler() {
        const message = "Olá!";

        SendWhatsAppMessage(Phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    return (
        <div className={"patient-item"}>
            <div className="patient-info">
                <div style={{ width: "10px", minWidth: "10px" }}>
                    <PatientStatus Value={Status} />
                </div>
                <p className="patient-item-text-name">{Name}</p>
                <p className="patient-item-text-cpf">{GetFriendlyCPF(CPF)}</p>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems:"center", margin:"10px"}}>
                    <p className="patient-item-text-phone">{Phone}</p>
                    <CustomButtom style={"secondary"} variant="outlined" icon={<IconWhatsapp color='primary' />} onClick={onWhatsappClickHandler} />
                </div>

            </div>
            <div className="patient-buttons">
                <NavLink to={Id} style={{ textDecoration: 'none' }}>
                    <CustomButtom id={1} label={"Ficha"} style={"secondary"} variant="outlined" icon={<ProfileIcon />} onClick={OnSelectHandler} />
                </NavLink>
            </div>
        </div>
    );
}

export default PatientItem;
