import React from 'react';
import styles from './DocumentDefaultTemplate.module.css';
import Header from './header/Header';
import Signature from './signature/Signature';
import Footer from './footer/Footer';
import PersonInfos from './person-infos/PersonInfos';

import LogoImage from '../../assets/images/logo-dourado.png';
import SignatureImage from '../../assets/images/signature.png';
import { GetFriendlyCPF } from '../../utils/Utils';

const DocumentDefaultTemplate = ({ companyData, patientData, professionalData, date, title, description }) => {

    return (
        <div className={styles.paper}>
            <div className={styles.content}>
                <div>
                    <Header logo={LogoImage} title={companyData.Name} address={companyData.Address} CNPJ={companyData.CNPJ} phone={companyData.Phone} />
                    <div style={{ display: "flex", flex: "1", gap: "20px", flexDirection: "row", alignContent: "space-between", marginTop: "20px", marginBottom: "20px" }}>
                        <PersonInfos data={[{ key: "Paciente:", value: patientData.name }, { key: "CPF:", value: GetFriendlyCPF(patientData.cpf) }]} />
                        <PersonInfos data={[{ key: "Profissional:", value: professionalData.Label }, { key: "Registro:", value: professionalData.Regulator }]} />
                    </div>
                    <div className={styles.textContainer}>
                        <h2>{title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }} ></p>
                    </div>
                </div>
                <Signature date={date} name={professionalData.Label} sign={SignatureImage} />
                <Footer />
            </div>
        </div>
    );
};

export default DocumentDefaultTemplate;