import React, { useEffect, useState, useRef } from 'react';
import './Dashboard.css';
import { DataProviderInstance } from '../../api/DataProvider';
import PatientBirthdayItem from './patientBirthdayItem/PatientBirthdayItem';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import Loading from '../../components/loading/Loading';
import IconAppointments from "@mui/icons-material/EventAvailableOutlined";
import IconBirthday from "@mui/icons-material/CakeOutlined";
import AppointmentsToConfirm from './appointmentsToConfirm/AppointmentsToConfirm';

function Dashboard() {

  const
    {
      UserData,
      CompanyConfig,
      GetDashboard
    } = DataProviderInstance()

  const [dashboardData, setDashboardData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    GetData();
  }, []);

  function GetData() {
    GetDashboard((result) => {
      setDashboardData(result);
      setIsLoaded(true);
    });
  };

  function GetGreetings() {

    let greetings = "";

    const currentHour = new Date().getHours();
    const name = UserData.name.split(' ')[0];

    if (currentHour >= 0 && currentHour < 12) {
      greetings =  `Bom dia ${name}!`;
    } else if (currentHour >= 12 && currentHour < 18) {
      greetings =  `Boa tarde ${name}!`;
    } else {
      greetings =  `Boa noite ${name}!`;
    }

    return greetings
  }

  function GetHeader() {
    const greetings = GetGreetings();
    return (
      <div className='dashboard-welcome'>
        <h2>{greetings}</h2>
        <p>Aqui estão alguns dados relevantes sobre seu dia de hoje.</p>
      </div>
    )
  }

  function GetPatientsBirthdays() {
    return (
      <div className='dashboard-item-container'>
        <AreaTitle Title={"Aniversariantes"} Description={"Não deixe de dar os parabéns!"} Icon={<IconBirthday />} />
        <div style={{ display: "flex", flex: "1", flexDirection: "column", marginTop: "14px", alignItems: "flex-start", gap:"10px" }}>
          {dashboardData.PatientsBirthdays.map(function (item) {
            return <PatientBirthdayItem Name={item.name} Age={item.age} Phone={item.phone} />
          })}
        </div>
      </div>
    )
  }  
  
  function GetAppointmentsToConfirm() {
    return (
      <div className='dashboard-item-container'>
        <AreaTitle Title={"Agendamentos"} Description={"Confirme os próximos agendamentos."} Icon={<IconAppointments />} />
        <div style={{ marginTop: "24px", gap:"20px"}}>
        <AppointmentsToConfirm list={dashboardData.AppointmentsToConfirm} />
        </div>
      </div>
    )
  }

  return (
    isLoaded ?
      <div className='page-content' >
        {GetHeader()}
        <div style={{ display: "flex", flex: "1", flexDirection: "column", flexWrap: "wrap", gap: "10px", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>{GetPatientsBirthdays()}</div>
          <div style={{ flex: "1" }}>{GetAppointmentsToConfirm()}</div>
        </div>
      </div> : <div className='page-content'><Loading /></div>
  );
}


export default Dashboard;