import React, { forwardRef, useState, useImperativeHandle } from "react";

import RadioItem from "./RadioItem/RadioItem";
import './RadioSelector.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const RadioSelector = forwardRef((props, ref) => {

    const { Label, Options, InitialSelection, Mandatory, OnSelect } = props;

    const [currentSelectedIndex, setCurrentSelectedIndex] = useState(InitialSelection? GetIndexById(InitialSelection) : 0);

    useImperativeHandle(ref, () => ({
        Validate() {
            if (ValidateValue()) {
                SetNormalState();
                return true;
            }
            else {
                SetErrorState();
                return false;
            }
        },
        GetValue() {
            return Options[currentSelectedIndex].id;
        },
        SetValue(value) {
            SetNormalState();
            setCurrentSelectedIndex(GetIndexById(value));
        }
    }));

    function ValidateValue() {
        if (Mandatory) {
            if (currentSelectedIndex == -1) {
                return false
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    function SetNormalState() {
        setCurrentClassState("radio-selector-options-list");
    }

    function SetErrorState() {
        setCurrentClassState("radio-selector-options-list-error");
    }

    function GetIndexById(id) {
        let itemIndex = -1;

        for (var index = 0; index < Options.length; index++) {
            if (Options[index].id == id) {
                itemIndex = index;
                break;
            }
        }
        return itemIndex;
    }

    function OnItemClickHandler(index) {
        SelectItem(index);
    }

    function SelectItem(index) {
        SetNormalState();
        setCurrentSelectedIndex(index);
        OnSelect(index);
    }

    let count = -1;

    let [currentClassState, setCurrentClassState] = useState("radio-selector-options-list");

    return (

        <FormControl>
            <FormLabel >{Label}</FormLabel>
            <RadioGroup
            row
                value={currentSelectedIndex}
                onChange={(event) => setCurrentSelectedIndex(GetIndexById(event.target.value))}
            >
                {Options.map((option) => (
                    <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={option.Label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
    return (

        <div className="radio-selector-content">
            <div className="radio-selector-label">{Label}</div>
            <div className={currentClassState}>
                {Options.map(item => {
                    count++;
                    return <RadioItem key={count} Id={count} Icon={item.Icon} Label={item.Label} Actived={(currentSelectedIndex == count) ? true : false} OnClick={OnItemClickHandler} />
                })}
            </div>
        </div>
    );
});

export default RadioSelector;