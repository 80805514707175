import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Appointment.module.css';
import CustomButton from "../../components/buttons/CustomButton";
import IconDoc from '@mui/icons-material/Description';
import IconStart from '@mui/icons-material/PlayArrow';
import IconStop from '@mui/icons-material/StopSharp';
import IconMoney from '@mui/icons-material/MonetizationOn';
import IconSave from '@mui/icons-material/Save';
import { SetPatientComplaints, UpdateAppointmentStatusAPI, GetAppointmentFullInfo } from '../../api/API';
import { DataProviderInstance } from "../../api/DataProvider";
import AppointmensState from '../../consts/AppointmentsState';
import Loading from '../../components/loading/Loading';
import AppointmentInfo from './components/appointmentInfo/AppointmentInfo';
import PatientInfo from './components/patientInfo/PatientInfo';
import PatientHistory from './components/patientHistory/PatientHistory';
import AnamneseHistory from './components/anamneseHistory/AnamneseHistory';
import MainPain from './components/mainPain/MainPain';
import Documents from './components/documents/Documents';
import Entry from './components/entry/Entry';
import AppointmentDoneConfirmPopupContent from '../../popup/AppointmentDoneConfirmPopupContent/AppointmentDoneConfirmPopupContent';
import CreateInvoicePopup from '../../popup/CreateInvoicePopup/CreateInvoicePopup';
import CreateDocumentPopup from '../../popup/CreateDocumentPopup/CreateDocumentPopup';

function Appointment() {
  const statusNotInitialized = 'notInitialized';
  const statusInitialized = 'initialized';
  const statusFinished = 'finished';
  const statusRegistered = 'registered';

  const navigate = useNavigate();

  const { SetPatientHistory } = DataProviderInstance();

  const { id } = useParams();
  const [data, setData] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(statusNotInitialized);
  const [currentSeconds, setCurrentSeconds] = useState(0);

  const [todayAssessment, setTodayAssessment] = useState("");
  const [todayEvolution, setTodayEvolution] = useState("");
  const [complaints, setComplaints] = useState([]);

  const [intervalId, setIntervalId] = useState(null);
  const [confirmPopupVisibility, setConfirmPopupVisibility] = useState(false);
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [showCreateDocumentPopup, setShowCreateDocumentPopup] = useState(false);

  const [invoiceCreated, setInvoiceCreated] = useState(false);

  useEffect(() => {
    LoadData();
  }, [id]);

  useEffect(() => {
    if (currentStatus === statusInitialized) {
      StartTimer();
    }
    else {
      StopTimer();
    }

  }, [currentStatus]);

  function LoadData() {

    GetAppointmentFullInfo(id, (response) => {

      let initialComplaints = [];

      if (response.complaints && response.complaints.length > 0) {
        initialComplaints = JSON.parse(response.complaints[0].list);
        setComplaints(initialComplaints);
      }

      if (response.appointment.status === AppointmensState.Doing) {

        if (localStorage.getItem('startTime') == null) {
          localStorage.setItem('startTime', Date.now());
        }

        setCurrentStatus(statusInitialized);
      }
      else if (response.appointment.status === AppointmensState.Done) {
        setCurrentStatus(statusRegistered);

        if (response.appointment.invoiceId != 0) {
          setInvoiceCreated(true);
        }
      }

      setData(response);
    });
  }

  function OnStartHandler() {
    UpdateAppointmentStatusAPI(data.appointment.id, AppointmensState.Doing, (response) => { });
    localStorage.setItem('startTime', Date.now());
    setCurrentStatus(statusInitialized);
  }

  function GetElapsedTime() {
    const startTime = localStorage.getItem('startTime');
    const currentTime = Date.now();
    const timeDifferenceInSeconds = Math.floor((currentTime - startTime) / 1000);

    return timeDifferenceInSeconds;
  }

  function StartTimer() {

    setCurrentSeconds(GetElapsedTime());

    let interval = setInterval(() => {
      setCurrentSeconds(GetElapsedTime());
    }, 1000);

    setIntervalId(interval);
  }

  function StopTimer() {
    clearInterval(intervalId);
    setIntervalId(null);
  }

  function OnFinishHandler() {
    StopTimer();
    setCurrentStatus(statusFinished);
  }

  function OnSaveHandler() {
    OpenConfirmationPopup();
  }

  function RegisterAppointmentHandler() {
    RegisterAppointment();
  }

  function RegisterAppointment() {
    let assessment = todayAssessment;
    let evolution = todayEvolution;

    let previous = (data.complaints.length > 0) ? JSON.parse(data.complaints[0].list) : [];
    const previousComplaints = JSON.stringify(previous);
    const currentComplaints = JSON.stringify(complaints);

    ClearAll();

    SetPatientHistory(data.appointment.date, data.patient.id, data.appointment.id, assessment, evolution, (response) => {

      if (previousComplaints !== currentComplaints) {
        SetPatientComplaints(data.appointment.date, data.patient.id, data.appointment.id, complaints, (response) => {
          OnAllDataSaved(response);
        });
      }
      else {
        OnAllDataSaved(response);
      }
    });
  }

  function OpenConfirmationPopup() {
    setConfirmPopupVisibility(true);
  }

  function OnInvoiceHandler() {
    setShowInvoicePopup(true);
  }

  function onChangeComplaintsHandler(values) {

    setComplaints(values);
  }

  function ClearAll() {
    setCurrentSeconds(0);
    //inputAssessmentRef.current.Clear();
    //inputEvolutionRef.current.Clear();
  }

  function OnAllDataSaved(response) {
    setCurrentStatus(statusRegistered);
    UpdateAppointmentStatusAPI(data.appointment.id, AppointmensState.Done, (response) => {
      ReloadPage();
    });
  }

  function ReloadPage() {
    LoadData();
  }

  function GoToAppointmentsScreen() {
    navigate("/schedule/appointments");
  }

  function GetCurrentButton() {

    if (currentStatus === statusNotInitialized) {
      return (
        <CustomButton
          variant="contained"
          style="primary"
          label={"Iniciar"}
          icon={<IconStart />}
          onClick={OnStartHandler}
          fullWidth={false}
        />
      );
    } else if (currentStatus === statusInitialized) {
      return (
        <CustomButton
          variant="contained"
          style="primary"
          label={"Finalizar"}
          icon={<IconStop />}
          onClick={OnFinishHandler}
          fullWidth={false}

        />)
    } else if (currentStatus === statusFinished) {
      return (
        <CustomButton
          variant="contained"
          style="primary"
          label={"Registrar"}
          icon={<IconSave />}
          onClick={OnSaveHandler}
          fullWidth={false}
        />)
    }
    else if (currentStatus === statusRegistered) {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "20px", height: "50px" }}>
          <CustomButton
            variant="contained"
            style="primary"
            label={"Emitir documento"}
            icon={<IconDoc />}
            onClick={OnCreateDocumentHandler}
            fullWidth={false}
          />

          {invoiceCreated ?
            <div style={{ display: "flex", gap: "10px" }}>
              {/*<CustomButton
                variant="outlined"
                style="tertiary"
                label={"Ver recibo"}
                icon={<IconMoney />}
                onClick={OnInvoiceViewHandler}
                fullWidth={false}
              />*/}
              <CustomButton
                variant="contained"
                style="primary"
                label={"Emitir nota fiscal"}
                icon={<IconMoney />}
                onClick={OnInvoiceViewHandler}
                fullWidth={false}
                enabled={false}
              />
            </div> :
            <CustomButton
              variant="contained"
              style="primary"
              label={"Faturar atendimento"}
              icon={<IconMoney />}
              onClick={OnInvoiceHandler}
              fullWidth={false}
            />
          }
        </div>
      )
    }
  }

  function onAssesmentChangeHandler(value) {
    setTodayAssessment(value);
  }

  function onEvolutionChangeHandler(value) {
    setTodayEvolution(value);
  }

  function OnInvoiceCreatedHandler() {
    setInvoiceCreated(true);
    ReloadPage();
  }

  function OnInvoiceViewHandler() {
    navigate("/invoice/" + data.appointment.invoiceId);
    //window.open(APPOINTMENT_GET_RECEIPT + "?id=" + data.appointment.id, "_blank");
  }

  function OnCreateDocumentHandler() {
    setShowCreateDocumentPopup(true);
    /*navigate("/document/" + data.appointment.id);*/
  }

  if (data == null) {
    return (
      <Loading />
    )
  }
  else {

    let enabled = currentStatus !== statusNotInitialized && currentStatus !== statusRegistered;
    let initialComplaints = [];

    if (data.complaints && data.complaints.length > 0) {
      initialComplaints = JSON.parse(data.complaints[0].list);
    }

    let appointmentDoneData = {
      duration: currentSeconds,
      appointment: data.appointment,
      patient: data.patient,
      complaints: complaints,
      todayAssessment: todayAssessment,
      todayEvolution: todayEvolution,
    };

    return (
      <div className='page-content'>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className={styles.containerWhiteBox} style={{ flex: "1" }}>
            <AppointmentInfo data={data.appointment} enabled={enabled} elapsedTime={currentSeconds} button={GetCurrentButton()} />
          </div>
          {enabled && <div className={styles.containerWhiteBox} style={{ flex: "1" }}>
            <Entry onAssesmentChange={onAssesmentChangeHandler} onEvolutionChange={onEvolutionChangeHandler} />
          </div>}
          <div className={styles.container}>
            <div style={{ display: "flex", width: "100%", flexWrap: "wrap", flexDirection: "row", gap: "20px", alignItems: "flex-start", alignContent: "flex-start" }}>
              <div className={styles.containerWhiteBox} style={{ display: "flex", flex: "1", flexDirection: "row" }}>
                <PatientInfo data={data.patient} enabled={enabled} />
              </div>
              <div className={styles.containerWhiteBox} style={{ display: "flex", flex: "1", flexDirection: "column", height:"205px" }}>
                <MainPain list={initialComplaints} onChange={onChangeComplaintsHandler} enabled={enabled} />
              </div>
            </div>
            <div className={styles.containerWhiteBox} style={{ flex: "1", alignItems: "flex-start", alignContent: "flex-start" }}>
              <AnamneseHistory patientId={data.patient.id} currentDate={data.appointment.date} list={data.anamneses} onUpdated={ReloadPage} enabled={enabled} />
            </div>
            <div className={styles.containerWhiteBox} style={{ flex: "1", alignItems: "flex-start", alignContent: "flex-start" }}>
              <PatientHistory data={data.history} enabled={enabled} />
            </div>
            <div className={styles.containerWhiteBox} style={{ flex: "1" }}>
              <Documents patientData={data.patient} list={data.documents} />
            </div>

          </div>
        </div>

        {confirmPopupVisibility && <AppointmentDoneConfirmPopupContent Data={appointmentDoneData} OnSave={RegisterAppointmentHandler} OnHide={() => setConfirmPopupVisibility(false)} />}
        {showInvoicePopup && <CreateInvoicePopup Data={data.appointment} OnCreated={OnInvoiceCreatedHandler} OnHide={() => setShowInvoicePopup(false)} />}
        {showCreateDocumentPopup && <CreateDocumentPopup Data={data.appointment} OnHide={() => setShowCreateDocumentPopup(false)} />}
      </div>
    );
  }

}

export default Appointment;
