import React, { useState } from 'react';
import styles from './AppointmentInfo.module.css';
import CalendarConfig from '../../../../consts/CalendarConfig';
import CircleIcon from '../../../../components/circleIcon/CircleIcon';
import Icon from "@mui/icons-material/AccessTime";

import { DataProviderInstance } from '../../../../api/DataProvider';

const AppointmentInfo = ({ data, enabled, elapsedTime, button }) => {

  const
    {
      GetProcedureById
    } = DataProviderInstance();

  function GetElapsedTime(currentSeconds) {
    let hours = Math.floor(currentSeconds / 3600);
    let minutes = Math.floor(currentSeconds / 60);
    let seconds = currentSeconds % 60;

    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const secondValue = data.date.split(" ")[1].split(":").slice(0, 2).join(":");

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "20px", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "20px", flexWrap: "wrap", alignItems: "center" }}>
          <CircleIcon icon={<Icon />} />
          <div className={styles.containerInfos}>
            <h5>{CalendarConfig.GetFullDateName(CalendarConfig.GetDDMMAAAA(data.date))} : {secondValue}</h5>
            <h4>{GetProcedureById(data.procedure).Label}</h4>
          </div>
          {enabled && <div className={styles.timer}>{GetElapsedTime(elapsedTime)}</div>}
        </div>
        {button}
      </div>
    </div>
  );
};

export default AppointmentInfo;
