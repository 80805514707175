import React from 'react';
import { useEffect, useState } from 'react';
import './Patients.css';
import { DataProviderInstance } from '../../api/DataProvider';
import Header from './header/Header';
import Loading from '../../components/loading/Loading';
import PatientItem from './list/PatientItem';
import CreatePatientPopup from '../../popup/CreatePatientPopup/CreatePatientPopup';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function Patients(props) {
  const
    {
      isUpdating,
      patients
    } = DataProviderInstance();

  const maxItemsPerPage = 10;
  const [currentPatient, setCurrentPatient] = useState(null);
  const [createPatientPopupVisibility, ShowCreatePatientPopup] = useState(false);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [patientsList, setPatientsList] = useState([]);

  useEffect(() => {
    resetCurrentPage();
    UpdatePatientsList();
  }, [patients, filter]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const resetCurrentPage = () => {
    setCurrentPage(0);
  };

  function OnCreatePatientPopupHideHandler() {
    setCurrentPatient(null);
    ShowCreatePatientPopup(false);
  }

  function OnOpenCreatePatientPopupHandler() {
    ShowCreatePatientPopup(true);
  }

  function OnPatientItemSelectHandler(id) {
    //var patient = GetPatientById(id);

    //ShowCreatePatientPopup(true);
    // setCurrentPatient(patient);
  }

  function GetPatientById(id) {
    var patient = patients.find((item) => {
      return item.id === id;
    });

    return patient;
  }

  function UpdatePatientsList() {

    let filteredList = [...patients];

    // Sort by Word filter
    if (filter.trim() !== "") {
      filteredList = filteredList.filter((patient) => {
        return patient.name.toLowerCase().startsWith(filter.toLowerCase());
      });
    }

    // Sort by Alphabetic
    filteredList.sort((a, b) => a.name.localeCompare(b.name));

    setPatientsList(filteredList);
    setTotalPages(Math.ceil(filteredList.length / maxItemsPerPage));
  }

  function GetList() {

    let list = [];

    // Pagination
    let start = currentPage * maxItemsPerPage;
    let end = start + maxItemsPerPage;

    if (end > patientsList.length) {
      end = patientsList.length;
    }

    for (let i = start; i < end; i++) {
      let { id, name, cpf, status, phone, email, origin } = patientsList[i];

      list.push(
        <PatientItem
          key={id}
          Id={id}
          Status={status}
          CPF={cpf}
          Name={name}
          Phone={phone}
          Email={email}
          Origin={origin}
          OnSelect={OnPatientItemSelectHandler}
        />
      );
    }

    return list;
  }

  function OnSearchHandler(value) {
    setFilter(value);
  }

  function GetPagination() {
    return (
      <div style={{ display: "flex", flex:'1', flexDirection: "row",  paddingBlock: "10px", justifyContent: "space-between", alignItems: "center", flexWrap:"wrap", gap:"20px" }}>
        <h5>Página {currentPage + 1} de {totalPages} ({patientsList.length} pacientes)</h5>
          <Pagination count={totalPages} page={currentPage + 1} onChange={handlePageChange} color="primary" shape='rounded' size='small'/>
      </div>
    );
  }

  function GetContent() {
    if (isUpdating) {
      return (<Loading />)
    }
    else {
      return (
        <div>
          <CreatePatientPopup Data={currentPatient} Visibility={createPatientPopupVisibility} OnHide={OnCreatePatientPopupHideHandler} />
          <Header OnCreatePatient={OnOpenCreatePatientPopupHandler} OnSearch={OnSearchHandler} />
          {GetPagination()}
          <div className='patients-list'>
            {GetList()}
          </div>
          {GetPagination()}
        </div>
      )
    }
  }
  return (
    <div className='page-content'>
      {GetContent()}
    </div>
  );
}

export default Patients;
