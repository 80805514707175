import React, { useEffect, useState, useRef } from 'react';
import './Company.module.css';
import Loading from '../../components/loading/Loading.js';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup.js';

function Company() {

  const [data, setData] = useState({});
  const [popup, setPopup] = useState(true);

  if (data == null) {
    return <Loading />;
  } else {
    return (
      <div className='page-content' >
        <button  onClick={()=> setPopup(true)} >Shpert</button>
        <div style={{backgroundColor:"red", width:"2500px", height:"3500px"}}> Textao</div>
        <ConfirmPopup Visibility={popup} OnHide={() => setPopup(false)} Title="Title" Description="Desc" />
      </div>
    );
  }
}


export default Company;
