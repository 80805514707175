import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './WhatsappMessages.module.css';
import Loading from '../../components/loading/Loading.js';
import CustomButton from '../../components/buttons/CustomButton.js';
import MessageList from './components/MessageList.js';
import MessageView from './components/MessageView.js';  
import { GetWhatsappMessages, SendWhatsappMessage } from '../../api/API.js';

function WhatsappMessages() {
  
  const [messages, setMessages] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    GetWhatsappMessages( (response) => {
      setMessages(response);
    });
  };

  function OnRefreshClickHandler() {
    LoadData();
  }

  function onMessageItemClickHandler(id) {
    const message = GetMessageById(id);
    setCurrentMessage(message);
  }

  function GetMessageById(id) {
    return messages.find(message => message.id === id);
  }

  const onSendMessageHandler = async (id, to, text) => {
    console.log('Sending message:', id, to, text);
   
    try {
      await SendWhatsappMessage(to, text);
      console.log('Message sent:', id, to, text);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (messages == null) {
    return <Loading />;
  } else {
    return (
      <div className='page-content' >
         <h2>Whatsapp Messages</h2>
         <CustomButton
            variant="outlined"
            style="primary"
            label={"Atualizar"}
            onClick={OnRefreshClickHandler}
          />
        <div style={{ display: 'flex', gap:"20px" }}>
          <MessageList messages={messages} onItemClick={onMessageItemClickHandler}/>
          {currentMessage ? <MessageView data={currentMessage} onSendMessage={onSendMessageHandler} /> : <></>}
        </div>
      </div>
    );
  }
}

export default WhatsappMessages;
