import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import CustomButton from '../../../components/buttons/CustomButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

function Header({ history, startDate, onDateChange, onGenerateReport }) {

    const [currentDate, setCurrentDate] = useState(startDate);

    const [initialDate, setInitialDate] = useState(dayjs(new Date(), 'YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs(new Date(), 'YYYY-MM-DD'));

    useEffect(() => {
        setCurrentDate(startDate);
    }, []);


    const handDateChange = (event) => {
        setCurrentDate(event.target.value);
        onDateChange(event.target.value);
    }

    const generateReport = () => {
        onGenerateReport();
    }

    const handleThisWeek = () => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
        const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
        setInitialDate(dayjs(startOfWeek));
        setEndDate(dayjs(endOfWeek));
    }

    const handleThisMonth = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setInitialDate(dayjs(startOfMonth));
        setEndDate(dayjs(endOfMonth));
    }

    const handleLastMonth = () => {
        const today = new Date();
        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        setInitialDate(dayjs(startOfLastMonth));
        setEndDate(dayjs(endOfLastMonth));
    }

    const handleLastWeek = () => {
        const today = new Date();
        const startOfLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 7));
        const endOfLastWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
        setInitialDate(dayjs(startOfLastWeek));
        setEndDate(dayjs(endOfLastWeek));
    }

    const handleThisYear = () => {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const endOfYear = new Date(today.getFullYear(), 11, 31);
        setInitialDate(dayjs(startOfYear));
        setEndDate(dayjs(endOfYear));
    }

    const handleLastYear = () => {
        const today = new Date();
        const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
        const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
        setInitialDate(dayjs(startOfLastYear));
        setEndDate(dayjs(endOfLastYear));
    }

    return (
        <div className={styles.mainContainer}>
            <FormControl>
                <InputLabel>Ano</InputLabel>
                <Select
                    value={currentDate}
                    label="Ano"
                    onChange={handDateChange}>
                    {history.map((item) => (
                        <MenuItem value={item.year}>{item.year}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Data de Início"
                    defaultValue={dayjs(new Date(), 'YYYY-MM-DD')}
                    value={initialDate}
                    format='DD/MM/YYYY'
                    onChange={(date) => setInitialDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                />

                <DatePicker
                    label="Data de Fim"
                    defaultValue={dayjs(new Date(), 'YYYY-MM-DD')}
                    format='DD/MM/YYYY'
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <div>
                <CustomButton variant="ghost" style={"primary"} label={"Essa semana"} onClick={handleThisWeek} />
                <CustomButton variant="ghost" style={"primary"} label={"Esse mês"} onClick={handleThisMonth} />
                <CustomButton variant="ghost" style={"primary"} label={"Esse ano"} onClick={handleThisYear} />
                <CustomButton variant="ghost" style={"primary"} label={"Semana Passada"} onClick={handleLastWeek} />
                <CustomButton variant="ghost" style={"primary"} label={"Mês passado"} onClick={handleLastMonth} />
                <CustomButton variant="ghost" style={"primary"} label={"Ano passado"} onClick={handleLastYear} />
            </div>
            <CustomButton variant="contained" style={"primary"} label={"Exportar"} onClick={generateReport} />
        </div>
    );
};

export default Header;
