const BASE_URL = "https://www.maisqueagenda.app.br/api/";

const LOGIN = BASE_URL + "login.php";

const COMPANY_CONFIG_GET = BASE_URL + "get_company.php";

const DASHBOARD_GET = BASE_URL + "get_dashboard.php";

const REPORTS_GET = BASE_URL + "get_reports.php";

const APPOINTMENTS_TO_CONFIRM_GET = BASE_URL + "get_appointments_to_confirm.php"
const APPOINTMENTS_GET = BASE_URL + "get_appointments.php";
const APPOINTMENTS_SET = BASE_URL + "set_appointment.php";
const APPOINTMENTS_UPDATE = BASE_URL + "update_appointment.php";
const APPOINTMENTS_UPDATE_STATUS = BASE_URL + "update_appointment_status.php";
const APPOINTMENTS_DELETE = BASE_URL + "delete_appointment.php";

const APPOINTMENT_GET_FULLINFO = BASE_URL + "get_appointment_info.php";
const APPOINTMENT_CREATE_INVOICE = BASE_URL + "set_appointment_invoice.php";
const APPOINTMENT_GET_RECEIPT = BASE_URL + "get_receipt_from_appointment.php";
const APPOINTMENT_GET_INVOICE = BASE_URL + "get_invoice.php";

const PATIENTS_GET = BASE_URL + "get_patients.php";
const PATIENT_GET = BASE_URL + "get_patient.php"
const PATIENT_SET = BASE_URL + "set_patient.php";
const PATIENT_UPDATE = BASE_URL + "update_patient.php";

const PATIENT_SET_HISTORY = BASE_URL + "set_patient_history.php";
const PATIENT_SET_COMPLAINTS = BASE_URL + "set_patient_complaint.php";
const PATIENT_SET_ANAMNESE = BASE_URL + "set_patient_anamnese.php";
const PATIENT_SET_DOCUMENT = BASE_URL + "set_patient_document.php";
const PATIENT_GET_DOCUMENTS = BASE_URL + "get_patient_documents.php";

const WHATSAPP_GET_MESSAGES = BASE_URL + "whatsapp/get_messages.php";
const WHATSAPP_SEND_MESSAGE = BASE_URL + "whatsapp/send_messages.php";

const GetWhatsappMessages = (callback) => {
    CallGETAPI(WHATSAPP_GET_MESSAGES, callback);
}

const SendWhatsappMessage = (to, text, callback) => {
    let json = "to=" + to + "&text=" + text;
    // CallPOSTAPI(WHATSAPP_SEND_MESSAGE, json, callback);
}

const GetInvoiceById = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENT_GET_INVOICE, json, callback);
}

const GetAppointmentFullInfo = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENT_GET_FULLINFO, json, callback);
}

const SetPatientAnamneseAPI = (date, createdBy, patientId, appointmentId, anamnese, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&createdBy=" + createdBy +
        "&anamnese=" + JSON.stringify(anamnese);

    CallPOSTAPI(PATIENT_SET_ANAMNESE, json, callback);
}

const SetPatientComplaints = (date, patientId, appointmentId, complaints, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&complaints=" + JSON.stringify(complaints);

    CallPOSTAPI(PATIENT_SET_COMPLAINTS, json, callback);
}

const SetPatientDocumentAPI = (patientId, date, appointmentId, title, description, createdBy, callback) => {

    let json = "appointmentId=" + appointmentId +
        "&date=" + date +
        "&patientId=" + patientId +
        "&title=" + title +
        "&description=" + description +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET_DOCUMENT, json, callback);
}

const GetPatientDocumentsAPI = (patientId, callback) => {
    let json = "patientId=" + patientId;
    CallPOSTAPI(PATIENT_GET_DOCUMENTS, json, callback);
}

const SetPatientHistoryAPI = (date, createdBy, patientId, appointmentId, assessment, evolution, callback) => {

    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&assessment=" + assessment +
        "&evolution=" + evolution +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET_HISTORY, json, callback);
}

const SetAppointmentInvoiceAPI = (appointmentId, companyId, createdBy, patient, price, paymentMethod, items, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&companyId=" + companyId +
        "&patient=" + patient +
        "&price=" + price +
        "&paymentMethod=" + paymentMethod +
        "&items=" + JSON.stringify(items) +
        "&createdBy=" + createdBy;

    CallPOSTAPI(APPOINTMENT_CREATE_INVOICE, json, callback);
}

const GetCompanyConfigAPI = (companyId, callback) => {
    let json = "id=" + companyId;
    CallPOSTAPI(COMPANY_CONFIG_GET, json, callback);
}

const LoginAPI = (email, password, callback) => {
    let json = "email=" + email + "&password=" + password;
    CallPOSTAPI(LOGIN, json, callback);
}

const GetDashboardAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    console.log("GetDashboardAPI", companyId)
    CallPOSTAPI(DASHBOARD_GET, json, callback);
}

const GetReportsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(REPORTS_GET, json, callback);
}

const GetAppointmentsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(APPOINTMENTS_GET, json, callback);
}

const SetAppointmentAPI = (companyId, createdBy, data, callback) => {
    let json = "status=" + data.status + "&date=" + data.date + "&patient=" + data.patient + "&resource=" + data.resource + "&procedure=" + data.procedure + "&duration=" + data.duration + "&price=" + data.price + "&companyId=" + companyId + "&createdBy=" + createdBy;
    CallPOSTAPI(APPOINTMENTS_SET, json, callback);
}

const UpdateAppointmentStatusAPI = (id, status, callback) => {
    let json = "id=" + id + "&status=" + status;
    CallPOSTAPI(APPOINTMENTS_UPDATE_STATUS, json, callback);
}
const UpdateAppointmentAPI = (data, callback) => {
    let json = "id=" + data.id + "&status=" + data.status + "&date=" + data.date + "&patient=" + data.patient + "&resource=" + data.resource + "&procedure=" + data.procedure + "&duration=" + data.duration + "&price=" + data.price;
    CallPOSTAPI(APPOINTMENTS_UPDATE, json, callback);
}

const DeleteAppointmentAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENTS_DELETE, json, callback);
}

const GetAppointmentsToConfirmAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(APPOINTMENTS_TO_CONFIRM_GET, json, callback);
}

const GetPatientAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(PATIENT_GET, json, callback);
}

const GetPatientsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(PATIENTS_GET, json, callback);
}

const SetPatientAPI = (companyId, createdBy, data, callback) => {
    let json = "name=" + data.name +
        "&companyId=" + companyId +
        "&phone=" + data.phone +
        "&cpf=" + data.cpf +
        "&email=" + data.email +
        "&birthday=" + data.birthday +
        "&gender=" + data.gender +
        "&cep=" + data.cep +
        "&address=" + data.address +
        "&number=" + data.number +
        "&complement=" + data.complement +
        "&district=" + data.district +
        "&city=" + data.city +
        "&state=" + data.state +
        "&origin=" + data.origin +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET, json, callback);
}

const UpdatePatientAPI = (data, callback) => {
    let json = "id=" + data.id +
        "&status=" + data.status +
        "&name=" + data.name +
        "&phone=" + data.phone +
        "&cpf=" + data.cpf +
        "&email=" + data.email +
        "&birthday=" + data.birthday +
        "&gender=" + data.gender +
        "&cep=" + data.cep +
        "&address=" + data.address +
        "&number=" + data.number +
        "&complement=" + data.complement +
        "&district=" + data.district +
        "&city=" + data.city +
        "&state=" + data.state +
        "&origin=" + data.origin;

    CallPOSTAPI(PATIENT_UPDATE, json, callback);
}

const GetCEPInfoAPI = (value, callback) => {
    CallGETAPI("https://viacep.com.br/ws/" + value + "/json/", callback);
}

const CallGETAPI = (path, callback) => {
    fetch(path)
        .then(response => response.json())
        .then(data => callback(data));
}

const Disconnect = () => {

    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('password');

    window.location.href = '/login';
}

const CallPOSTAPI = (path, body, callback) => {

    const token = localStorage.getItem('token');

    const requestOptions =
    {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body
    };

    fetch(path, requestOptions)
        .then(response => {
            // Verifica se o token expirou (status 401)
            if (response.status === 401) {
                // Token expirado, deslogar o usuário
                console.warn('Token expirado, redirecionando para login.');

                Disconnect();

                return; // Para a execução aqui
            }

            // Se não for 401, continua normalmente
            return response.text();
        })
        .then(data => {
            try {
                // Verifica se 'data' não está vazio antes de tentar o parse
                if (data) {
                    const jsonData = JSON.parse(data);
                    callback(jsonData);
                } else {
                    console.error('Dados vazios recebidos da API');
                }
            } catch (e) {
                console.error('Erro ao analisar o JSON:', path, e);
            }
        })
        .catch(error => {
            console.error('Erro na requisição:', error);
        });
}

export {
    APPOINTMENT_GET_RECEIPT,
    LoginAPI,
    GetCompanyConfigAPI,
    GetCEPInfoAPI,
    GetDashboardAPI,
    GetReportsAPI,
    GetAppointmentsToConfirmAPI,
    UpdateAppointmentStatusAPI,
    GetPatientsAPI, GetPatientAPI, SetPatientAPI, UpdatePatientAPI,
    SetPatientHistoryAPI, SetPatientComplaints, SetPatientAnamneseAPI, SetPatientDocumentAPI, GetPatientDocumentsAPI,
    GetAppointmentsAPI, SetAppointmentAPI, UpdateAppointmentAPI, DeleteAppointmentAPI,
    SetAppointmentInvoiceAPI,
    GetAppointmentFullInfo,
    GetInvoiceById,
    GetWhatsappMessages, SendWhatsappMessage
};