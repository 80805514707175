import React, { useState, useEffect } from 'react';
import styles from './Appointments.module.css';
import { DataProviderInstance } from '../../api/DataProvider';
import Header from './header/Header';
import DayAppointments from './dayAppointments/DayAppointments';
import AppointmensState from '../../consts/AppointmentsState';
import CalendarConfig from '../../consts/CalendarConfig';
import { DayAppointmentsModel, AppointmentModel } from '../../consts/Models';
import Loading from '../../components/loading/Loading';
import AppointmentPopupContent from '../../popup/AppointmentPopupContent/AppointmentPopupContent';
import AvailableDatePopupContent from '../../popup/AvailableDatePopupContent/AvailableDatePopupContent';
import BlockAppointmentPopupContent from '../../popup/BlockAppointmentPopupContent/BlockAppointmentPopupContent';
import CreateInvoicePopup from '../../popup/CreateInvoicePopup/CreateInvoicePopup';
import AppConfig from '../../consts/AppConfig';
import FilterList from './components/filters/FilterList';
import CalendarDatePicker from './components/filters/calendarDatePicker/CalendarDatePicker';
import CustomButton from "../../components/buttons/CustomButton";
import CalendarTodayIcon from '@mui/icons-material/AddCircleOutline';

function Appointments() {
  const { isUpdating, CompanyConfig, appointments, GetAppointments } = DataProviderInstance();
  const [isMobile, setIsMobile] = useState(window.innerWidth < AppConfig.MinScreenSizeX);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [viewDaysCount, setViewDaysCount] = useState(7);
  const [page, setPage] = useState(0);
  const _today = GetCurrentDate();
  const [popupId, setPopupId] = useState(-1);

  const usingStatesSource = AppointmensState.Filters;

  const [filteredAppointments, setFilteredAppointments] = useState(appointments);
  const [filtersByDayOfWeek, setFiltersByDayOfWeek] = useState(CalendarConfig.DaysOfWeek.map((item, index) => index));
  const [filtersByState, setFiltersByState] = useState(usingStatesSource.map((item, index) => index));
  const [filtersByProcedures, setFiltersByProcedures] = useState(CompanyConfig.Procedures.map((item, index) => index));

  useEffect(() => {
    setIsMobile(window.innerWidth < AppConfig.MinScreenSizeX);

    GetAppointments((result) => {
      //setData(result);
    });

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);


  function handleResize() {
    if (window.innerWidth < AppConfig.MinScreenSizeX) {
      setIsMobile(true);
      // setViewDaysCount(1);
    } else {
      setIsMobile(false);
      // setViewDaysCount(7);
    }
  }

  useEffect(() => {

    let filteredAppointments = [...appointments];

    // Filter By Status
    filteredAppointments = filteredAppointments.filter((item) => filtersByState.includes(usingStatesSource.indexOf(item.status)));

    // Filter by Procedure
    let proceduresIds = filtersByProcedures.map(index => CompanyConfig.Procedures[index].Id);
    proceduresIds.push(null);
    proceduresIds.push("");

    filteredAppointments = filteredAppointments.filter((item) => {
      return item.procedure == "null" || proceduresIds.includes(item.procedure);
    });

    setFilteredAppointments(filteredAppointments);

  }, [filtersByState, filtersByProcedures]);

  function GetCurrentDate() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }
  function OnPrevClickHandler() {
    let newPage = page - 1;

    if (viewDaysCount === 1) {
      let newDate = getCurrentViewDate(_today, newPage, viewDaysCount);

      while (!filtersByDayOfWeek.includes(newDate.getDay())) {
        newPage -= 1;
        newDate = getCurrentViewDate(_today, newPage, viewDaysCount);
      }
    }

    setPage(newPage);
  }

  function OnNextClickHandler() {
    let newPage = page + 1;

    if (viewDaysCount === 1) {
      let newDate = getCurrentViewDate(_today, newPage, viewDaysCount);

      while (!filtersByDayOfWeek.includes(newDate.getDay())) {
        newPage += 1;
        newDate = getCurrentViewDate(_today, newPage, viewDaysCount);
      }
    }

    setPage(newPage);
  }

  function OnTodayClickHandler() {
    setPage(0);
  }

  function resetTime(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  function getCurrentViewDate(today, page, viewDaysCount) {
    const currentDayOfWeek = today.getDay();
    const startDateOffset = page * viewDaysCount;

    if (viewDaysCount > 1) { // Se for semana
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - currentDayOfWeek + (page * 7)); // Movendo para o início da semana
      startOfWeek.setHours(0, 0, 0, 0);
      return startOfWeek;
    } else {
      // Se for um único dia
      const newDate = new Date(today);
      newDate.setDate(today.getDate() + startDateOffset);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    }
  }

  function calculatePageFromDate(selectedDate, today, viewDaysCount) {
    const selected = new Date(selectedDate);
    const todayCopy = resetTime(today);

    const dayDiff = Math.floor((selected - todayCopy) / (1000 * 60 * 60 * 24));

    if (viewDaysCount > 1) { // Modo semanal
      const selectedStartOfWeek = new Date(selected);
      selectedStartOfWeek.setDate(selectedStartOfWeek.getDate() - selectedStartOfWeek.getDay());
      const todayStartOfWeek = new Date(todayCopy);
      todayStartOfWeek.setDate(todayStartOfWeek.getDate() - todayStartOfWeek.getDay());

      const weekDiff = Math.floor((selectedStartOfWeek - todayStartOfWeek) / (1000 * 60 * 60 * 24 * 7));
      return weekDiff;
    } else {
      return Math.floor(dayDiff / viewDaysCount);
    }
  }

  function OnSelectViewModeHandler(value) {

    const newViewDaysCount = value === 0 ? 1 : 7;
    const currentViewDate = getCurrentViewDate(_today, page, viewDaysCount);
    const newPage = calculatePageFromDate(currentViewDate, _today, newViewDaysCount);

    setViewDaysCount(newViewDaysCount);
    setPage(newPage);
  }

  function onDateChangeByCalendarDatePickerHandler(selectedDate) {

    const newPage = calculatePageFromDate(selectedDate, _today, viewDaysCount);
    setPage(newPage);
  }

  function GetDayAppointmentsModel(date) {

    let appointmentsFromDay = [];

    let hasApppointmentsInDay = FindAppointmentsInDay(date);
    let foundedAppointmentsInHour = null;
    let appointmentData = null;

    let times = CalendarConfig.GetCalendarTimes(CompanyConfig.AvailableTime, CompanyConfig.TimeInterval);

    for (let i = 0; i < times.length; i++) {
      let time = times[i];

      if (hasApppointmentsInDay) {
        foundedAppointmentsInHour = FindAppointmentsInHour(hasApppointmentsInDay, time);
      }

      if (foundedAppointmentsInHour) {
        foundedAppointmentsInHour.map(raw => {
          appointmentsFromDay.push(new AppointmentModel(
            raw.id,
            raw.status,
            raw.date,
            raw.patient,
            raw.resource,
            raw.procedure,
            raw.duration,
            raw.price,
            raw.invoiceId
          ));
        });

      }
      else {

        let strDate = CalendarConfig.GetAAAAMMDD(date);
        let appointmentState = AppointmensState.Available;
        let hour = time.split(":")[0];

        if (CompanyConfig.BlockedTimes.includes(parseInt(hour))) {
          appointmentState = AppointmensState.Unavailable;
        }

        appointmentData = new AppointmentModel(
          null,//"available"+ time,
          appointmentState,
          strDate + " " + time + ":00",
        );

        appointmentsFromDay.push(appointmentData);
      }

    }

    var dayAppointments = new DayAppointmentsModel(date, appointmentsFromDay);

    return dayAppointments;
  }

  function FindAppointmentsInDay(date) {
    // dateTarget é um DATE;
    let dateTarget = new Date(date);
    dateTarget.setHours(0, 0, 0, 0);

    let result = [];

    for (let i = 0; i < filteredAppointments.length; ++i) {
      let appointmentDate = filteredAppointments[i];

      let dateAppointment = new Date(appointmentDate.date)
      dateAppointment.setHours(0, 0, 0, 0);

      if (dateAppointment.toDateString() === dateTarget.toDateString()) {
        result.push(appointmentDate);
      }
    }

    return (result.length > 0) ? result : null;
  }

  function FindAppointmentsInHour(sourceList, time) {
    let result = [];

    let targetHour = parseInt(time.split(":")[0]);
    let targetMinute = parseInt(time.split(":")[1]);

    for (let i = 0; i < sourceList.length; ++i) {
      let appointmentData = sourceList[i];

      //if (appointmentData.status == AppointmensState.Canceled) continue;

      let dateAppointment = new Date(appointmentData.date);
      let dateHour = dateAppointment.getHours();
      let dateMinutes = dateAppointment.getMinutes();

      if (dateHour === targetHour) {
        result.push(appointmentData);
      }

    }

    return (result.length > 0) ? result : null;
  }

  function GetDaysDifFromToday() {
    if (viewDaysCount == 1) {
      return (viewDaysCount * page);
    }
    else {
      return (viewDaysCount * page) - _today.getDay();
    }
  }

  function CurrentViewDay() {
    const currentDayOfWeek = _today.getDay();
    const startDateOffset = page * viewDaysCount;

    if (viewDaysCount > 1) { // Se for semana
      const startOfWeek = new Date(_today);
      startOfWeek.setDate(_today.getDate() - currentDayOfWeek + (page * 7)); // Movendo para o início da semana
      startOfWeek.setHours(0, 0, 0, 0);
      return startOfWeek;
    } else {
      // Se for um único dia
      let newDate = new Date(_today);
      newDate.setDate(_today.getDate() + startDateOffset);
      newDate.setHours(0, 0, 0, 0);

      // Verificar se o dia está no filtro de dias da semana
      while (!filtersByDayOfWeek.includes(newDate.getDay())) {
        newDate.setDate(newDate.getDate() + (startDateOffset > 0 ? 1 : -1)); // Avançar ou retroceder um dia
      }

      return newDate;
    }
  }

  function GetGrid() {
    let grid = [];

    for (let i = 0; i < viewDaysCount; i++) {

      let newDate = new Date();
      newDate.setDate(_today.getDate() + GetDaysDifFromToday() + i);
      newDate.setHours(0, 0, 0, 0);

      let dayAppointmentsModel = GetDayAppointmentsModel(newDate);

      if (filtersByDayOfWeek.includes(newDate.getDay())) {
        grid.push(<DayAppointments key={i} Data={dayAppointmentsModel} OnSelect={OnItemSelectHandler} />);
      }

      /* 
          const isSunday = newDate.getDay() === 0;
          const isSaturday = newDate.getDay() === 6;
    
          if(isSunday || isSaturday)
            {
              if(isSunday && filtersByDayOfWeek.includes(0))
                {
                  grid.push(<DayAppointments key={i} Data={dayAppointmentsModel} OnSelect={OnItemSelectHandler} />);
                }
      
              if(isSaturday && filtersByDayOfWeek.includes(1))  
              {
                grid.push(<DayAppointments key={i} Data={dayAppointmentsModel} OnSelect={OnItemSelectHandler} />);
              }
            }
            else 
            {
              grid.push(<DayAppointments key={i} Data={dayAppointmentsModel} OnSelect={OnItemSelectHandler} />);
            }
       */

    }

    return grid;
  }

  function OnItemSelectHandler(appointmentData) {

    ShowPopup(appointmentData);
  }

  function ShowPopup(appointmentData) {

    setCurrentAppointment(appointmentData);

    // -1 = hidden
    // 0 = available date
    // 1 = appointment details
    // 2 = block date

    if (appointmentData.status == AppointmensState.Available) {
      setPopupId(0);
    }
    else if (appointmentData.status == AppointmensState.Blocked) {
      setPopupId(2);
    }
    else {
      setPopupId(1);
    }
  }

  function OnHidePopupHandler() {
    setCurrentAppointment(null);
    setPopupId(-1);
  }

  function GetMonthView() {
    let currentViewDay = CurrentViewDay();
    let today = GetCurrentDate();

    const diffTime = Math.abs(currentViewDay - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 7) {
      return today.getMonth();
    }
    else {
      return CurrentViewDay().getMonth();
    }
  }

  function CreateAppointmentHandler(data) {
    setCurrentAppointment(data);
    setPopupId(1);
  }

  function CreateBlockHandler(data) {
    setCurrentAppointment(data);
    setPopupId(2);
  }

  function CreateInvoiceFromAppointmentHandler(data) {
    setCurrentAppointment(data);
    setPopupId(3);
  }

  function GetPopup() {
    switch (popupId) {
      case 0:
        return <AvailableDatePopupContent Data={currentAppointment} CreateBlockCallback={CreateBlockHandler} CreateAppointmentCallback={CreateAppointmentHandler} OnHide={OnHidePopupHandler} />
      case 1:
        return <AppointmentPopupContent Data={currentAppointment} OnCreateInvoice={CreateInvoiceFromAppointmentHandler} OnHide={OnHidePopupHandler} />;
      case 2:
        return <BlockAppointmentPopupContent Data={currentAppointment} OnHide={OnHidePopupHandler} />;
      case 3:
        return <CreateInvoicePopup Data={currentAppointment} OnHide={OnHidePopupHandler} />;
      default:
        return null;
    }
  }

  function GetFilterByDayOfWeek() {
    const filters = CalendarConfig.DaysOfWeek;
    return <FilterList id="dayOfWeek" label="Dia da Semana" filters={filters} onChange={onFilterByDayOfWeekChangeHandler} />
  }

  function GetFilterByAppointmentStatus() {
    const filters = usingStatesSource.map((item) => AppointmensState.GetLabel(item));
    return <FilterList id="status" label="Situação" filters={filters} onChange={onFilterByAppointmentsStatusChangeHandler} />
  }

  function onFilterByDayOfWeekChangeHandler(filters) {
    setFiltersByDayOfWeek(filters);
  }

  function onFilterByAppointmentsStatusChangeHandler(filters) {
    setFiltersByState(filters);
  }

  function GetFilterByAppointmentProcedures() {
    const filters = CompanyConfig.Procedures.map((item) => item.Label);
    return <FilterList id="procedures" label="Procedimentos" filters={filters} onChange={onFilterByAppointmentsProceduresChangeHandler} />
  }

  function onFilterByAppointmentsProceduresChangeHandler(filters) {
    setFiltersByProcedures(filters);
  }

  function GetContent() {
    if (isUpdating) {
      return <Loading />;
    } else {

      return (
        <div className={styles.mainContainer}>
          {GetPopup()}
          <div className={styles.filtersContainer}>
            <CalendarDatePicker currentDate={getCurrentViewDate(_today, page, viewDaysCount)} viewDaysCount={viewDaysCount} onChange={onDateChangeByCalendarDatePickerHandler} />
            {GetFilterByAppointmentStatus()}
            {GetFilterByAppointmentProcedures()}
            {GetFilterByDayOfWeek()}
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between" }}>
              <h2>Agenda</h2>
              <CustomButton variant="contained" style="primary" label={"Adicionar"} icon={<CalendarTodayIcon />} onClick={() => { setPopupId(0) }} />
            </div>
            <Header isMobile={isMobile} Month={CalendarConfig.Months[GetMonthView()]} Year={CurrentViewDay().getFullYear()} OnSelectViewMode={OnSelectViewModeHandler} OnPrev={OnPrevClickHandler} OnNext={OnNextClickHandler} OnToday={OnTodayClickHandler} />
            <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "30px", height:"100%"}}>
              <div className={styles.calendar}>
                <div style={{ display: "flex",  flex:"1" ,flexDirection: "row", gap: "20px", height:"100%"}}>
                  {GetGrid()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return (
    <div className='page-content'>
      {GetContent()}
    </div>
  );
}

export default Appointments;