import React, { useState, useEffect } from 'react';
import styles from './AppointmentItem.module.css';
import AppointmentItem from './AppointmentItem';

function HourSlotItem(props) {
  const { isToday, time, currentTime, appointments, OnSelect } = props;

  const [sourceList, setSourceList] = useState(appointments);
  const [isOver, setOver] = useState(false);

  let currentTimePosition = getCurrentTimePosition();

  useEffect(() => {
    setSourceList(appointments);
  }, [appointments]);

  useEffect(() => {
    currentTimePosition = getCurrentTimePosition();
  }, [currentTime]);

  function OnItemSelectHandler(item) {
    OnSelect(item);
  }

  const GetContent = () => {
    return sourceList.map((item, index) => AddAppointment(item, index));
  };

  function isCurrentTime() {
    if (!isToday) return false;

    let myTime = new Date();
    myTime.setHours(parseInt(time.split(':')[0]), parseInt(time.split(':')[1]), 0, 0);

    let max = new Date();
    max.setHours(parseInt(time.split(':')[0]) + 1, parseInt(time.split(':')[1]), 0, 0);

    return myTime <= currentTime && currentTime < max;
  }

  function getCurrentTimePosition() {
    if (!isToday) return null;

    let hourStart = new Date();
    hourStart.setHours(parseInt(time.split(':')[0]), 0, 0, 0);
    let hourEnd = new Date();
    hourEnd.setHours(parseInt(time.split(':')[0]) + 1, 0, 0, 0);

    let diff = (currentTime - hourStart) / (hourEnd - hourStart);
    return diff * 100; // returns percentage of time passed within the hour
  }

  function AddAppointment(appointmentModel, index) {
    return <AppointmentItem key={appointmentModel.date + index} appointment={appointmentModel} OnSelect={OnItemSelectHandler} />;
  }

  function GetStyle() {
    let style = styles.mainContainer;

    if (isOver) {
      style = styles.mainContainerOver;
    }

   // if(true)
    //  style = styles.mainContainerBusy;

    return style;
  }

  return (
    <div
      className={GetStyle()}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className={isCurrentTime() ? styles.dateActived : styles.date}>{time}</div>
      {isCurrentTime() && (
        <div
          className={styles.currentTimeLine}
          style={{ top: `${currentTimePosition}%` }}
        ></div>
      )}
      <div className={styles.appointmentsContainer}>        
        {GetContent()}
      </div>
    </div>
  );

  function handleMouseEnter() {
    setOver(true);
  }

  function handleMouseLeave() {
    setOver(false);
  }
}

export default HourSlotItem;
