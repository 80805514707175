import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.css';
import { CreatePDF } from '../../utils/Utils';
import { DataProviderInstance } from '../../api/DataProvider';
import { PieChart, LineChart, BarChart, ChartsReferenceLine, ChartContainer, LinePlot, MarkPlot, ChartsXAxis, ChartsYAxis, ChartsLegend, ChartsTooltip, ChartsAxisHighlight, BarPlot } from '@mui/x-charts';
import CalendarConfig from '../../consts/CalendarConfig';
import PatientConfig from '../../consts/PatientConfig';
import ColorPalette from '../../colorpalette/ColorPalette';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import IconAppointments from "@mui/icons-material/Schedule";
import IconFinance from "@mui/icons-material/MonetizationOnOutlined";
import IconPatients from "@mui/icons-material/PersonAddAlt1Outlined";
import IconPatientsGender from "@mui/icons-material/PeopleAltOutlined";
import Loading from '../../components/loading/Loading';
import { FormControlLabel, Switch } from "@mui/material";
import Header from './header/Header';

function Statistics() {

  const
    {
      CompanyConfig,
      GetReports,
    } = DataProviderInstance()


  const [dashboardData, setDashboardData] = useState(null);
  const [currentHistoryYear, setCurrentHistoryYear] = useState(new Date().getFullYear().toString());
  const pdfContentRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    GetData();
  }, []);

  function GetData() {
    GetReports((result) => {

      if (result.AppointmentsHistory && result.AppointmentsHistory.length > 0) {
        setCurrentHistoryYear(result.AppointmentsHistory[result.AppointmentsHistory.length - 1].year);
      }
      else {
        console.log("No data found for appointments history.", new Date().getFullYear().toString());
      }

      setDashboardData(result);
      setIsLoaded(true);
    });
  };

  function handYearHistoryChange(value) {
    setCurrentHistoryYear(value);
  }

  function GetFinancialSummary() {

    let source = dashboardData.AppointmentsHistory;

    let currentYear = currentHistoryYear;

    const revenuesActual = [];
    const revenuesTotal = [];

    const labels = [];

    if (source && source.length > 0) {
      const filteredDataByYear = source.filter(item => item.year === currentYear)[0];

      let lastActualRevenue;
      let lastTotalRevenue;

      filteredDataByYear.months.map((item) => {
        revenuesActual.push(item.actualRevenue);
        revenuesTotal.push(item.totalRevenue);

        labels.push(CalendarConfig.Months[item.month - 1].substring(0, 3));

        lastActualRevenue = item.actualRevenue;
        lastTotalRevenue = item.totalRevenue;
      });
    }
    else {
      revenuesActual.push(0);
      revenuesTotal.push(0);
      labels.push("-----");
    }

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle Title={"Resumo financeiro"} Description={"Sua previsão de faturamento e o que já foi realizado"} Icon={<IconFinance />} />
        <ResponsiveChartContainer
          series={[
            {
              curve: "linear",
              hideTooltip: false,
              data: revenuesActual,
              label: 'Realizado',
              type: 'bar',
              color: ColorPalette.main,
              highlightScope:
              {
                faded: 'global',
                highlighted: 'item',
                xAxis: 'item',
                yAxis: 'item',
                stack: 'total',
              },
            },
            {
              curve: "linear",
              hideTooltip: false,
              data: revenuesTotal,
              label: 'Previsto',
              type: 'line',
              color: ColorPalette.secondary,
              highlightScope:
              {
                faded: 'global',
                highlighted: 'item',
                xAxis: 'item',
                yAxis: 'item',
                stack: 'total',
              }
            },
          ]}
          xAxis={[{ scaleType: 'band', data: labels }]}
        >
          <BarPlot />
          <MarkPlot />
          <ChartsReferenceLine y={CompanyConfig.GoalBilling} label="Meta" lineStyle={{ stroke: ColorPalette.grayDark, strokeDasharray: "4 4" }} />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
          <ChartsAxisHighlight x={"line"} y={true} />
        </ResponsiveChartContainer>
      </div>
    )
  }

  function GetAppointmentsHistoryGraph() {
    let source = dashboardData.AppointmentsHistory;

    let currentYear = currentHistoryYear;

    let completedValues = [];
    let missedValues = [];
    let labels = [];
    let filteredDataByYear = [];

    if (source && source.length > 0) {

      filteredDataByYear = source.filter(item => item.year === currentYear)[0];

      filteredDataByYear.months.map((item) => {
        completedValues.push(item.completed);
        missedValues.push(item.missed);
        labels.push(CalendarConfig.Months[item.month - 1].substring(0, 3));
      });

    }
    else {
      completedValues.push(0);
      missedValues.push(0);
      labels.push("-----");
    }

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle Title={"Atendimentos"} Description={"Realizados durante o ano e sua meta."} Icon={<IconAppointments />} />
        <ResponsiveChartContainer
          series={[
            {
              curve: "linear",
              hideTooltip: false,
              data: completedValues,
              label: 'Realizados',
              type: 'line',
              color: '#206afa',
              highlightScope:
              {
                faded: 'global',
                highlighted: 'item',
                xAxis: 'item',
                yAxis: 'item',
              }
            },
            //        { curve: "linear", data: missedValues, label: 'Faltas', type: 'line', color: 'red', showMark: false },
          ]}
          xAxis={[{ scaleType: 'point', data: labels }]}
        >
          <LinePlot />
          <MarkPlot />
          <ChartsReferenceLine y={CompanyConfig.GoalAppointments} label="Meta" lineStyle={{ stroke: ColorPalette.grayDark, strokeDasharray: "4 4" }} />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
          <ChartsAxisHighlight x={"line"} y={true} />
        </ResponsiveChartContainer>
      </div>
    );
  }

  function GetPatientsGenderGraph() {

    const palette = [ColorPalette.main, ColorPalette.secondary];

    let source = [];
    let data = [];

    if (dashboardData.PatientsHistory.genders.length > 0) {
      source = dashboardData.PatientsHistory.genders;
    }

    source.map((item) => {
      var serie = {};
      serie.id = item.gender;
      serie.value = item.count;
      serie.label = PatientConfig.GetGenderById(item.gender).Label;

      data.push(serie);
    }
    );

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle Title={"Pacientes"} Description={"Categorizados por gênero"} Icon={<IconPatientsGender />} />
        <PieChart
          colors={palette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}

          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: -10,
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />

      </div>
    )

  }

  const [showUnkownOrigin, setShowUnkownOrigin] = useState(false);

  function GetPatientsCreated() {

    let source = [];
    let labelsSet = new Set(); // Para garantir que não haja duplicações de rótulos
    let series = [];

    // Verifica se há dados e se há origens configuradas
    if (dashboardData.PatientsHistory.created.length > 0 && CompanyConfig.PatientOrigins.length > 0) {
      source = dashboardData.PatientsHistory.created;

      // Inicializa as séries com as origens configuradas
      series = CompanyConfig.PatientOrigins.map((origin) => ({
        data: [],
        id: origin.Id.toString(), // Certifique-se de que todos os IDs sejam strings
        label: origin.Label,
        stack: 'total',
      }));
    }

    // Mapeia os dados da API para preencher os rótulos e as séries
    source.forEach(item => {
      let labelDate = `${item.year}/${item.month}`;
      labelsSet.add(labelDate); // Adiciona o rótulo (mês/ano) ao conjunto de rótulos

      // Para cada origem encontrada no item
      item.origins.forEach((origin) => {

        if (!showUnkownOrigin) {
          // Ignora origem -1 com valor 0
          if (origin.id == "-1" || origin.id == "0") return;
        }

        let originId = origin.id === "-1" ? "0" : origin.id.toString(); // Tratamento para ID -1

        // Encontra a série correspondente para a origem atual
        let serie = series.find(s => s.id === originId);
        if (serie) {
          serie.data.push(parseInt(origin.count, 10)); // Adiciona o valor da origem ao mês correspondente
        } else {
          console.log("Série não encontrada para a origem:", origin.id);
        }
      });

      // Preenche as séries que não têm dados para o mês atual com 0
      series.forEach(serie => {
        if (serie.data.length < labelsSet.size) {
          serie.data.push(0); // Preenche com 0 se não houver valor para o mês atual
        }
      });
    });

    let labels = Array.from(labelsSet); // Converte o conjunto de rótulos em array

    // Garante que todas as séries tenham o número correto de pontos
    series.forEach(serie => {
      while (serie.data.length < labels.length) {
        serie.data.push(0); // Preenche com 0 se estiver faltando algum valor
      }
    });

    let axis = [{ scaleType: 'band', data: labels }]; // Configura o eixo X com os rótulos (meses)

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle Title={"Novos pacientes"} Description={"Categorizados por origem de cadastro."} Icon={<IconPatients />} />
        <BarChart
          xAxis={axis}
          series={series}
          colors={[ColorPalette.textLight2, ColorPalette.main, ColorPalette.secondary, "#E2FCD6"]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showUnkownOrigin}
              onChange={() => setShowUnkownOrigin(!showUnkownOrigin)}
            />
          }
          label="Exibir origens desconhecidas"
        />
      </div>
    );
  }

  function onGenerateReportHandler()
  {
    GenerateReport();
  }

  function GenerateReport() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}/${month}/${day}`;
    const fileName = `Relatorio_${formattedDate}.pdf`;

    CreatePDF(pdfContentRef.current, fileName);
  }

  return (
    isLoaded ?
      <div className='page-content' style={{gap:"20px", display:"flex", flexDirection:"column"}}>
        <Header history={dashboardData.AppointmentsHistory} startDate={currentHistoryYear} onDateChange={handYearHistoryChange} onGenerateReport={onGenerateReportHandler} />
        <div ref={pdfContentRef} style={{ display: "flex", flex: "1", flexDirection: "row", flexWrap: "wrap", gap: "10px", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>{GetAppointmentsHistoryGraph()}</div>
          <div style={{ flex: "1" }}>{GetFinancialSummary()}</div>
          <div style={{ flex: "1" }}>{GetPatientsCreated()}</div>
          <div style={{ flex: "1" }}>{GetPatientsGenderGraph()}</div>

        </div>
      </div> : <div className='page-content'><Loading /></div>
  );
}


export default Statistics;