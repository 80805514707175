import React, { useEffect, useState, createContext, useContext } from 'react';

import { SetAppointmentInvoiceAPI, SetPatientAnamnese, SetPatientDocumentAPI, GetPatientDocumentsAPI } from './API';
import { GetCompanyConfigAPI } from './API';
import { GetDashboardAPI } from './API';
import { GetReportsAPI } from './API';
import { GetPatientsAPI, SetPatientAPI, UpdatePatientAPI } from './API';
import { GetAppointmentsToConfirmAPI } from './API';
import { GetAppointmentsAPI, SetAppointmentAPI, UpdateAppointmentAPI, DeleteAppointmentAPI } from './API';
import { GetCEPInfoAPI } from './API';
import { SetPatientAnamneseAPI } from './API';
import { SetPatientHistoryAPI } from './API';

const DataContext = createContext();

export const DataProvider = ({ children, userData, companyId, OnComplete }) => {

  const [isUpdating, setIsUpdating] = useState(false);
  const [UserData, setUserData] = useState(userData);
  const [CompanyConfig, setCompanyConfig] = useState(null);
  const [patients, SetPatients] = useState([]);
  const [appointments, SetAppointments] = useState([]);
  const [appointmentsToConfirm, SetAppointmentsToConfirm] = useState([]);

  useEffect(() => {
    if (companyId === null) {
      return;
    }

    const fetchData = async () => {
      try {
        await Promise.all([GetCompany(), GetPatients(), GetAppointments()]).then(() => {

          setTimeout(() => {
            OnComplete();
            setIsUpdating(false);
          }, 1000);

        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [companyId]);

  const SetAppointmentInvoice = async (appointmentId, patient, price, paymentMethod, items, callback) => {
    setIsUpdating(true);

    const createdBy = userData.id;

    SetAppointmentInvoiceAPI(appointmentId, companyId, createdBy, patient, price, paymentMethod, items, (response) => {
      GetAppointments();
      callback(response);
    });
  }

  const GetCompany = async () => {

    GetCompanyConfigAPI(companyId, (result) => {
      let data = {};

      data.Name = result.name;
      data.Address = result.address;
      data.Phone = result.phone;
      data.CNPJ = result.cnpj;
      data.Logo = result.logo;

      data.AvailableDaysOfWeek = JSON.parse(result.availableDaysOfWeek);
      data.AvailableTime = JSON.parse(result.availableTime);
      data.BlockedTimes = JSON.parse(result.blockedTimes);
      data.TargetOccupation = JSON.parse(result.targetOccupation);
      data.TimeInterval = JSON.parse(result.timeInterval);
      data.GoalAppointments = JSON.parse(result.goalAppointments);
      data.GoalBilling = JSON.parse(result.goalBilling);

      data.Resources = result.resources;
      data.Procedures = result.procedures;
      data.PaymentOptions = result.paymentOptions;
      data.PatientOrigins = result.patientOrigins;

      data.PatientComplaints = result.patientComplaints;
      data.DocumentTemplates = result.documentTemplates;
      data.AnamneseTemplates = result.anamneseTemplates;

      setCompanyConfig(data);
    });
  };

  const GetCEPInfo = (cep, callback) => {
    GetCEPInfoAPI(cep, (result) => {
      callback(result);
    });
  }

  const GetPatients = async (dispatchUpdatingEvent = true, callback) => {
    if(dispatchUpdatingEvent) setIsUpdating(true);
    GetPatientsAPI(companyId, (result) => {
      SetPatients(result);
      if(callback) callback(result);
      if(dispatchUpdatingEvent)setIsUpdating(false);
    });
  };

  const GetPatientById = (id) => {
    return patients.find((element) => {
      return element.id === id;
    })
  }

  const SetPatient = async (data) => {

    const createdBy = userData.id;

    SetPatientAPI(companyId, createdBy, data, () => {
      GetPatients();
    });
  }

  const UpdatePatient = async (data) => {
    UpdatePatientAPI(data, () => {
      GetPatients();
    });
  }

  const GetAppointmentsToConfirm = async () => {
    setIsUpdating(true);
    GetAppointmentsToConfirmAPI(companyId, (result) => {
      SetAppointmentsToConfirm(result);
      setIsUpdating(false);
    });
  };


  const GetAppointments = async () => {

    setIsUpdating(true);
    GetAppointmentsAPI(companyId, (result) => {
      SetAppointments(result);
      setIsUpdating(false);
    });
  };

  const GetAppointmentById = (id) => {
    let result = appointments.find((element) => {
      return element.id === id;
    });

    return result;
  }

  const SetAppointment = async (data, callback) => {

    const createdBy = userData.id;

    SetAppointmentAPI(companyId, createdBy, data, (response) => {

      GetAppointments();

      if (callback !== undefined) {
        console.log("SetAppointmentAPI", response);
        callback(response);
      }
    });
  }

  const UpdateAppointment = async (data) => {
    UpdateAppointmentAPI(data, () => {
      GetAppointments();
    });
  }

  const DeleteAppointment = async (id) => {
    DeleteAppointmentAPI(id, () => {
      GetAppointments();
    });
  }

  const GetDashboard = async (data) => {
    GetDashboardAPI(companyId, data, () => {
    });
  }

  const GetReports = async (data) => {
    GetReportsAPI(companyId, data, () => {
    });
  }

  function GetProcedureById(id) {
    return CompanyConfig.Procedures.find(p => p.Id == id);
  }

  function GetResourceById(id) {
    return CompanyConfig.Resources.find(p => p.Id == id);
  }

  const SetPatientAnamnese = async (currentDate, patientId, appointmentId, json, callback) => {

    const createdBy = userData.id;

    SetPatientAnamneseAPI(currentDate, createdBy, patientId, appointmentId, json, (response) => {
      callback(response);
    });
  };

  const SetPatientHistory = async (currentDate, patientId, appointmentId, assessment, evolution, callback) => {

    const createdBy = userData.id;

    SetPatientHistoryAPI(currentDate, createdBy, patientId, appointmentId, assessment, evolution, (response) => {
      callback(response);
    });
  };

  const SetPatientDocument = async (patientId, date, appointmentId, title, description, callback) => {

    const createdBy = userData.id;

    SetPatientDocumentAPI(patientId, date, appointmentId, title, description, createdBy, (response) => {
      callback(response);
    });
  };

  const GetPatientDocuments = async (patientId, callback) => {
    GetPatientDocumentsAPI(patientId, (response) => {
      callback(response);
    });
  };

  const allData =
  {
    isUpdating,
    UserData,
    CompanyConfig,
    GetProcedureById, GetResourceById,
    GetCEPInfo,
    GetDashboard,
    GetReports,
    patients, GetPatients, SetPatient, UpdatePatient, GetPatientById,
    SetPatientAnamnese, SetPatientDocument, GetPatientDocuments,
    appointmentsToConfirm, GetAppointmentsToConfirm,
    appointments, GetAppointments, GetAppointmentById, SetAppointment, UpdateAppointment, DeleteAppointment,
    SetAppointmentInvoice,
    SetPatientHistory
  };

  return (
    <DataContext.Provider value={allData}>
      {children}
    </DataContext.Provider>
  );
};

export const DataProviderInstance = () => useContext(DataContext);