import React from 'react';
import style from './MessageItem.module.css';

const MessageItem = ({ data, onClick } ) => (
    <li className={style.container} onClick={onClick}>
        <strong>{data.sender}:</strong> {data.text} <em>({new Date(data.timestamp).toLocaleString()})</em>
    </li>
);

export default MessageItem;
