import React, { useState } from "react";
import PopupBase from "../base/PopupBase";
import { DataProviderInstance } from '../../api/DataProvider';
import CustomButton from "../../components/buttons/CustomButton";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function ComplaintsPopupContent(props) {
    const
        {
            CompanyConfig,
        } = DataProviderInstance();

    const { OnSave, OnHide, InitialData } = props;
    const [error, setError] = useState(false);
    const filter = createFilterOptions();
    const [selectedItems, setSelectedItems] = useState([]);

    function ValidateForm() {

        if(selectedItems.length === 0)
        {
            setError(true);
            return false;
        }
        
        return true;
    }

    function OnCloseClickHandler() {
        Hide();
    }

    function OnConfirmClickHandler() {
        if (ValidateForm()) {
            OnSave(selectedItems);
            Hide();
        }
        else {
            console.log("invalid form");
        }
    }

    function Hide() {
        OnHide();
    }

    function GetTitle() {

        return "Adicionar queixas";
    }

    function GetOptionsForSelect() {

        const defaultOptions = CompanyConfig.PatientComplaints;

        return defaultOptions.map((item, index) => {
            return { id: index, label: item };
        });
    }

    function OnAutocompleteSelectHandler(value) {

        if (value) {
            setSelectedItems(value);
        }
        else {
            setSelectedItems([]);
        }
    }

    function GetContent() {
        return (
            <div>
                <Autocomplete
                    multiple
                    id="combo-box-demo"
                    options={GetOptionsForSelect()}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                label: params.inputValue,
                            });
                        }

                        return filtered;
                    }}
                    getOptionDisabled={(option) =>
                        InitialData.some(item => item === option.label) || selectedItems.some(item => item.label === option.label)
                            ? true : false
                      }
                    sx={{ width: "100%" }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Queixa"
                            error={error}
                            helperText={error ? 'Selecione uma queixa' : ''}
                        />}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => OnAutocompleteSelectHandler(value)}
                />
            </div>
        )
    }

    function GetPositiveButton() {

        return (
            <CustomButton style="primary" variant="contained" label={"Adicionar"} onClick={OnConfirmClickHandler} />
        )
    }
   
    function GetRender() {

        return <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetPositiveButton()}  OnClose={OnCloseClickHandler} />
    }

    return (GetRender());
}

export default ComplaintsPopupContent;