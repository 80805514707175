import React from 'react';
import { useState } from 'react';
import PopupBase from "../base/PopupBase";
import { DataProviderInstance } from '../../api/DataProvider';
import CalendarConfig from '../../consts/CalendarConfig';
import AppointmentsState from "../../consts/AppointmentsState";
import CustomButton from "../../components/buttons/CustomButton";
import IconBlocked from '@mui/icons-material/BlockOutlined';
import IconDelete from '@mui/icons-material/DeleteOutline';
import TextField from '@mui/material/TextField';
import Header from "../AppointmentPopupContent/header/Header";

function BlockAppointmentPopupContent(props) {

    const { Data, OnHide } = props;
    const { SetAppointment, UpdateAppointment, DeleteAppointment } = DataProviderInstance();
    const [reason, setReason] = useState('');

    function OnCloseClickHandler() {
        Hide();
    }

    function Hide() {
        OnHide();
    }

    function OnPositiveButtonClickHandler() {
        CreateBlockAppointment();
        Hide();
    }

    function OnDeleteHandler() {
        DeleteBlockAppointment();
        Hide();
    }

    function DeleteBlockAppointment() {
        DeleteAppointment(Data.id);
    }

    function CreateBlockAppointment() {
        var date = CalendarConfig.ISOToDate(Data.date);
        var parsedDate = CalendarConfig.GetAAAAMMDD(date);
        var time = CalendarConfig.GetTime(Data.date);

        CreateAppointmentData(
            Data.id,
            AppointmentsState.Blocked,
            parsedDate,
            time,
            reason,
            "",
            "",
        );
    }

    function CreateAppointmentData(id, status, date, time, patient, resource, procedure) {
        var data = {
            id: id,
            status: status,
            date: date + " " + time,
            patient: patient,
            resource: resource,
            procedure: (procedure) ? procedure.Label : null,
            duration: (procedure) ? procedure.Duration : null,
            price: (procedure) ? procedure.Price : null,
        }

        // Create or update a appointment;
        if (id == null) {
            SetAppointment(data);
        }
        else {
            UpdateAppointment(data);
        }

        return data;
    }

    function GetTitle() {
        if (Data.id == null) {
            return "Bloquear horário";
        }
        else {
            return "Horário bloqueado";
        }
    }

    function GetFooterButtons() {
        if (Data.id == null) {
            return <CustomButton variant="contained" style="primary" label={"Bloquear"} icon={<IconBlocked />} onClick={OnPositiveButtonClickHandler} />
        }
        else {
            return <CustomButton variant="contained" style="warning" label={"Excluir bloqueio"} icon={<IconDelete />} onClick={OnDeleteHandler} />
        }
    }

    function GetContent() {

        let dateStr = CalendarConfig.GetDDMMAAAA(Data.date);
        let date = CalendarConfig.GetFullDateName(dateStr);
        let time = CalendarConfig.GetTime(Data.date);

        if (Data.id == null) {
            return (

                <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "0px" }}>
                     <Header Date={Data.date} />
                    <TextField multiline rows={5} label="Motivo" value={reason} onChange={(e) => setReason(e.target.value)} />
                </div>
            );
        }
        else {
            let reason = Data.patient;
            return (
                <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "30px" }}>
                    <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "10px" }}>
                        <span>{date}</span>
                        <span>{time}</span>
                    </div>
                    <div style={{ display: "flex", flex: "1", flexDirection: "column", gap: "6px" }}>
                    <b>Motivo:</b>
                    <p>{reason}</p>
                    </div>
                </div>
            );
        }
    }

    function GetRender() {
        return <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetFooterButtons()} OnClose={OnCloseClickHandler} />
    }

    return (GetRender());
}

export default BlockAppointmentPopupContent;