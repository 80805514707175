import React from "react";
import "./UserDisplay.css";
import Picture from '@mui/icons-material/AccountCircle';

function UserDisplay(props) {
    const { Name, Role } = props;

    return (
        <div className="userDisplay">
            <Picture className="userDisplayImg" fontSize="large"/>
            <div className="userDisplayInfos">
                <span>{Name}</span>
                <p>{Role}</p>
            </div>
        </div>
    );
}

export default UserDisplay;