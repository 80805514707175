import React from "react";
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ColorPalette from "../../colorpalette/ColorPalette";

function CustomButton(props) {
    const { id, style, variant, label, icon, fullWidth, onClick, enabled = true } = props;

    function GetColor(style) {
        let color = "";

        switch (style) {
            case "primary":
                color = ColorPalette.main;
                break;
            case "secondary":
                color = ColorPalette.textDark;
                break;
            case "tertiary":
                color = "#4F4F4F";
                break;
            case "warning":
                color = ColorPalette.yellow;
                break;
            case "error":
                color = ColorPalette.red;
                break;
            default:
                color = "#e1e1e1";
                break;
        }
        return color;
    }

    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    text: {
                        color: ColorPalette.textDark,
                        backgroundColor: "transparent",
                        '&:hover': {
                            backgroundColor: "#e1e1e1",
                        },
                    },
                    outlined: {
                        color: ColorPalette.textDark,
                        borderColor: "#e1e1e1",
                        backgroundColor: "transparent",
                        '&:hover': {
                            borderColor: GetColor(style),
                            backgroundColor: "transparent",
                        },
                    },
                    contained: {
                        backgroundColor: GetColor(style),
                        '&:hover': {
                            backgroundColor: GetColor(style),
                        },
                    },
                },
            },
        },
    });

    // Adicione o estilo da borda aqui
    theme.components.MuiButton.styleOverrides.root = {
        textTransform: "none",
        fontWeight: 400,
        padding: "0px 20px",
        height: "40px",
        fontFamily: "Roboto",
    };

    function OnClickHandler() {
        onClick(id);
    }

    return (
        <ThemeProvider theme={theme}>
            {label == null || label == "" ?
                <Button variant={variant} onClick={OnClickHandler} disableElevation disabled={!enabled} >
                    {icon}
                </Button>
                :
                <Button variant={variant} onClick={OnClickHandler} startIcon={icon} disableElevation disabled={!enabled} >
                    {label}
                </Button>
            }
        </ThemeProvider>
    )
}

export default CustomButton;
