import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { TextField, TextareaAutosize, Radio, Checkbox, RadioGroup, FormControlLabel } from "@mui/material";
import styles from './DynamicForm.module.css';
import Alert from '@mui/material/Alert';
import Info from '../info/Info';

const DynamicForm = forwardRef(({ questions }, ref) => {

    const errorMessage = "Resposta obrigatória";
    const TEXT_AREA = "textArea";
    const SINGLE_CHOICE = "singleChoice";
    const MULTIPLE_CHOICE = "multipleChoice";

    const [answers, setAnswers] = useState([]);
    const [observations, setObservations] = useState([]);

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setObservations([]);
        setAnswers([]);
        setErrors([]);
    }, []);

    function GetError(label) {
        return errors.find(error => error.label === label);
    }

    function removeError(label) {
        setErrors(prevErrors => prevErrors.filter(error => error.label !== label));
    }

    useImperativeHandle(ref, () => ({
        Validate() {

            const mandatoryFields = questions.filter(question => question.mandatory);
            const missingFields = mandatoryFields.filter(question => !answers.some(answer => answer.label === question.label));

            if (missingFields.length > 0) {
                missingFields.forEach(question => {
                    setErrors(prevErrors => [...prevErrors, { label: question.label, message: errorMessage }]);
                });

                return false;
            }

            const emptyFields = answers.filter(answer => mandatoryFields.some(question => question.label === answer.label && !answer.value));

            if (emptyFields.length > 0) {
                emptyFields.forEach(answer => {
                    setErrors(prevErrors => [...prevErrors, { label: answer.label, message: errorMessage }]);
                });

                return false;
            }

            return true;
        },
        GetValue() {

            const formattedAnswers = answers.map(answer => {
                const observation = observations.find(obs => obs.label === answer.label);
                return {
                    label: answer.label,
                    value: answer.value,
                    observation: observation ? observation.value : undefined
                };
            });

            return formattedAnswers;

            return [answers, observations];
        },
        Reset() {
            setAnswers([]);
            setObservations([]);
            setErrors([]);
        }
    }));

    function setAnswer(label, value) {

        const updatedAnswers = [...answers];
        const existingAnswer = updatedAnswers.find(answer => answer.label === label);

        if (existingAnswer) {
            existingAnswer.value = value;
        } else {
            updatedAnswers.push({ label, value });
        }

        setAnswers(updatedAnswers);
        removeError(label);
    }

    function setObservation(label, value) {

        const current = [...observations];
        const existing = current.find(observations => observations.label === label);

        if (existing) {
            existing.value = value;
        } else {
            current.push({ label, value });
        }

        setObservations(current);
    }
    function GetObservationInput(label) {
        return (
            <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center", marginTop: "10px" }}>
                <span>Observações:</span>
                <TextField fullWidth multiline rows={1} onChange={(e) => setObservation(label, e.target.value)} size='small' />
            </div>
        );
    }

    function renderInput({ index, label, type, observation, options }) {

        const error = GetError(label);

        if (type === TEXT_AREA) {
            return (
                <div>
                    <TextField fullWidth multiline rows={5} onChange={(e) => setAnswer(label, e.target.value)} error={!!error} helperText={error ? error.message : ''} size='small' tabIndex={index} />
                    {observation && GetObservationInput(label)}
                </div>
            );
        } else if (type === SINGLE_CHOICE) {
            return (
                <div>
                    <RadioGroup fullWidth name={`option-${label}`} value={answers.find(answer => answer.label === label)?.value} onChange={(e) => setAnswer(label, e.target.value)} error={!!error} size='small' tabIndex={index}>
                        {options.map((option, optionIndex) => (
                            <div key={optionIndex}>
                                <FormControlLabel
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                    size='small'
                                />
                            </div>
                        ))}
                    </RadioGroup>
                    {observation && GetObservationInput(label)}
                </div>
            );
        } else if (type === MULTIPLE_CHOICE) {
            return (
                <div>
                    {options.map((option, optionIndex) => (
                        <div key={optionIndex}>
                            <FormControlLabel fullWidth
                                error={!!error}
                                label={option}
                                onChange={() => {
                                    const selectedOptions = options.filter((opt, idx) => {
                                        const checkbox = document.getElementById(`checkbox-${label}-${idx}`);
                                        return checkbox.checked;
                                    });
                                    setAnswer(label, selectedOptions);
                                }}
                                control={
                                    <Checkbox
                                        id={`checkbox-${label}-${optionIndex}`}
                                        value={option}
                                        size='small'
                                        tabIndex={index}
                                    />
                                }
                            />
                            {observation && GetObservationInput(label)}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div >
                    <TextField type={type} onChange={(e) => setAnswer(label, e.target.value)} error={!!error} helperText={error ? error.message : ''} fullWidth size='small' tabIndex={index}/>
                    {observation && GetObservationInput(label)}
                </div>
            );
        }
    }

    return (
        <div ref={ref} className={styles.formContainer}>
            {questions.map((item, index) => (
                <div key={index} className={styles.itemContainer}>
                    <div key={index} className={styles.questionContainer}>
                        <span className={styles.question}>{item.mandatory && <span className={styles.mandatoryIndicator}>* </span>}{item.label}</span>
                        {item.tip && <Info description={item.tip}/>}
                    </div>
                    {GetError(item.label) && <Alert severity="error">{GetError(item.label).message}</Alert>}
                    {renderInput(index,item)}
                </div>
            ))}
        </div>
    );
});

export default DynamicForm;
