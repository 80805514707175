import React, { useState } from 'react';
import styles from './Entry.module.css';
import InputField from '../../../../components/inputfield/InputField';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import AssessmentIcon from "@mui/icons-material/ExpandMore";
import EvolutionIcon from "@mui/icons-material/ExpandLess";

const Entry = ({ onAssesmentChange, onEvolutionChange }) => {

    const [assessment, setAssessment] = useState("");
    const [evolution, setEvolution] = useState("");

    const numLines = 5;

    function onAssesmentChangeHandler(value) {
        setAssessment(value);
        onAssesmentChange(value);
    }

    function onEvolutionChangeHandler(value) {
        setEvolution(value);
        onEvolutionChange(value);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.itemContainer}>
               <AreaTitle Title={"Queixa"} Description={"Adicione informações sobre o estado atual do paciente."} Icon={<AssessmentIcon/>} />
                <InputField
                    label="Queixa"
                    multiline
                    rows={numLines}
                    OnUpdateValue={onAssesmentChangeHandler}
                />
            </div>
            <div className={styles.itemContainer}>
            <AreaTitle Title={"Evolução"} Description={"Escreva quais procedimentos foram feitos e demais observações."} Icon={<EvolutionIcon />} />
            <InputField
                    label="Evolução"
                    multiline
                    rows={numLines}
                    OnUpdateValue={onEvolutionChangeHandler}
                />
            </div>
        </div>
    );
};

export default Entry;