import React from 'react';
import MessageItem from './MessageItem';
import styles from './MessageList.module.css';

const MessageList = (props) => {
    const { messages, onItemClick } = props;

    const handleItemClick = (id) => {
        onItemClick(id);
    };

    return (
        <ul className={styles.container}>
            {messages.map((item) => (
                <MessageItem key={item.id} data={item} onClick={() => handleItemClick(item.id)} />
            ))}
        </ul>
    );
};

export default MessageList;
