import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Patient.module.css';
import Header from './components/Header';
import CreatePatientPopup from '../../popup/CreatePatientPopup/CreatePatientPopup';
import TabBar from '../../components/tab-bar/TabBar';
import PersonalInfo from './areas/personal-info/PersonalInfo';
import AppointmentsHistory from './areas/appointments-history/AppointmentsHistory';
import {GetPatientAPI} from '../../api/API';
import Loading from '../../components/loading/Loading';
import HealthHistory from './areas/heatlh-history/HealthHistory';
import Financial from './areas/financial/Financial';
import Documents from './areas/documents/Documents';

function Patient() {
  
  const { id } = useParams();

  const [createPatientPopupVisibility, ShowCreatePatientPopup] = useState(false);
  const [currentArea, setCurrentArea] = useState(0);
  const [patientData, setPatientData] = useState(null);

  let areas = [
    { id: 0, label: "Dados pessoais"},
    { id: 1, label: "Agendamentos"},
    { id: 2, label: "Saúde" },
    { id: 3, label: "Financeiro" },
    { id: 4, label: "Documentos" }
  ];

  useEffect(() => {
    setCurrentArea(0);
    LoadData();
  }, [id]);

  useEffect(() => {
    LoadData();
  }, [patientData]);

  const LoadData = () => {
    GetPatientAPI(id, (data) => {
      setPatientData(data);
    });
  }

  function OnEditClickHandler() {
    ShowEditPopup();
  }

  function ShowEditPopup() {
    ShowCreatePatientPopup(true);
  }

  function HideEditPopup() {
    ShowCreatePatientPopup(false);
  }

  function handleAreaChange(newValue) {
    setCurrentArea(newValue);
  };

  function GetArea()
  {
      switch (currentArea) {
        case 0:
          return <PersonalInfo data={patientData.personalInfo} onEdit={OnEditClickHandler}/>
        case 1:
          return <AppointmentsHistory data={patientData.appointments} />;
        case 2:
          return <HealthHistory complaints={patientData.complaints} evolutions={patientData.evolutions} anamneses={patientData.anamneses}/>;
        case 3:
          return <Financial patientData={patientData.personalInfo} data={patientData.financial} />;
          case 4:
            return <Documents patientData={patientData.personalInfo} list={patientData.documents} />;
        default:
          return <></>;
      }
  }

  function onPatientInfoChangeHandle(data) {
    LoadData();
  }

  function GetContent() {
   
      return (
        <div>
          <div className={styles.mainContainer}>
            <div className={styles.containerAreas}>
              <Header Patient={patientData.personalInfo}  />
              <TabBar labels={areas.map(item => item.label)} value={currentArea} onChange={handleAreaChange} />
              <div className={styles.containerArea} >
                {GetArea()}
              </div>            
            </div>
          </div>
          <CreatePatientPopup Data={patientData.personalInfo} Visibility={createPatientPopupVisibility} OnChange={onPatientInfoChangeHandle} OnHide={HideEditPopup} />
        </div>
      );
    }

  return (
    <div className='page-content'>
      {patientData == null ? <Loading /> : GetContent()}
    </div>
  );
}

export default Patient;
