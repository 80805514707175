import React from 'react';
import { useState } from 'react';

import './Sidebar.css';
import SidebarGroup from './group/SidebarGroup';
import AppConfig, { IDWhatsappMessages, IdSchedule_Appointments, IdCompany, IdDashboard, IdPatients, IdReports, IdResources, IdServices, IdSettings } from '../consts/AppConfig';
import { IdLogout } from '../consts/AppConfig';
import Logo from '../assets/images/logo-full.png';

function Sidebar() {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id="sidebar" className="sidebar-opened" >
      <img src={Logo} className="logo" alt="Logo" width={30} height={30} />
      <div className="sidebar-groups">
        {
          AppConfig.MenuRoutes.map((item) => (
            <SidebarGroup key={item.label} Items={item.routes} Label={isOpen ? item.label : ""}  IsOpen={isOpen} />
          ))
        }
      </div>
    </div>

  );
}

export default Sidebar;
