import React from 'react';
import styles from './PatientBirthdayItem.module.css';
import CustomButtom from '../../../components/buttons/CustomButton';
import MessagesTemplate from '../../../consts/MessagesTemplate';
import { SendWhatsAppMessage } from '../../../api/WhatsappAPI';
import IconWhatsapp from '@mui/icons-material/WhatsApp'
import { useEffect, useState } from 'react';
import { Check } from '@mui/icons-material';
import ColorPalette from '../../../colorpalette/ColorPalette';

function PatientBirthdayItem(props) {
    const { Name, Age, Phone } = props;

    const localStorageId = "birthDayMessageSent-" + Name + Age + Phone;

    const [messageSent, setMessagesSent] = useState(false);

    useEffect(() => {
        //localStorage.clear();
        setMessagesSent(GetMessagesSent());
    }, []);

    useEffect(() => {
        if (messageSent) {
            localStorage.setItem(localStorageId, true);
        }
    }, [messageSent]);

    function GetMessagesSent() {
        const value = localStorage.getItem(localStorageId);
        return value ? true : false;
    }

    function MessageSent() {
        setMessagesSent(true);
    }


    function OnContactClickHandler(e) {

        let message = MessagesTemplate.HappyBirthday;

        message = message.replaceAll("{name}", Name.split(' ')[0]);

        SendWhatsAppMessage(Phone, MessagesTemplate.ConvertToWhatsAppText(message));
        MessageSent();
    }

    return (
        <div className={styles.patientBirthDayContainer}>
            <div className={styles.patientBirthDayInfoContainer}>
                <span className={styles.patientBirthDayInfoAge}>{Age}</span>
                <span className={styles.name}>{Name}</span>
            </div>
            <div className={styles.containerActions}>
                {messageSent && <Check htmlColor={ColorPalette.green} fontSize='' />}
                <CustomButtom style={"secondary"} variant="outlined" icon={<IconWhatsapp color='primary' />} onClick={OnContactClickHandler} />
            </div>

        </div>
    )
}

export default PatientBirthdayItem;