import React from 'react';
import AppointmentToConfirmItem from './AppointmentToConfirmItem';
import { DataProviderInstance } from '../../../../api/DataProvider';
import styles from './AppointmentsByDay.module.css';
import CalendarConfig from '../../../../consts/CalendarConfig';

const AppointmentsByDay = ({ label, appointments }) => {

    const
        {
            GetProcedureById,
            GetResourceById,
            GetPatientById
        } = DataProviderInstance()

    function AddItem(item, index) {
        let patientData = GetPatientById(item.patient);
        let time = CalendarConfig.GetTime(item.date);

        let resource = GetResourceById(item.resource);
        let procedure = GetProcedureById(item.procedure);

        return (
            <AppointmentToConfirmItem
                key={index}
                Id={item.id}
                patientId={item.patient}
                State={item.status}
                Name={patientData.name}
                Date={item.date}
                Time={time}
                Phone={patientData.phone}
                Procedure={procedure.Label}
                Resource={resource.Label}
            />)
    }

    return (
        <div className={styles.containerToConfirm}>
            <h4>{label}</h4>
            <div className={styles.containerItems}>
                {appointments.map((item, index) => (
                    AddItem(item, index)
                ))}
            </div>
        </div>
    );
};

export default AppointmentsByDay;
